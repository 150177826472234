import React from 'react';
import './Button.css';

export interface IButtonParams {
    text: string;
    width?: string;
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: string;
    borderStyle?: string;
    color?: string;
    onClick: () => void;
    disable?: boolean;
    testId?: string;
    fontSize?: string;
    height?: string;
    borderRadius?: string;
    fontWeight?: string;
}

const Button: React.FC<IButtonParams> = ({
    text,
    testId,
    width,
    backgroundColor,
    color,
    borderColor,
    borderWidth,
    borderStyle,
    fontSize,
    onClick,
    disable = false,
    height,
    fontWeight,
    borderRadius,
}) => {
    const defaultBackgroundColor = backgroundColor ? backgroundColor : 'linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)';
    const defaultColor = color ? color : 'white';
    const defaultBorderRadius = borderRadius ? borderRadius : '24px';
    const defaultHeight = height ? height : '48px';
    const defaultBorderColor = borderColor ? borderColor : 'transparent';
    const defaultBorderWidth = borderWidth ? borderWidth : '1px';
    const defaultBorderStyle = borderStyle ? borderStyle : 'solid';
    return (
        <>
            <button
                className="btn"
                type="button"
                onClick={() => onClick()}
                data-testid={testId}
                id={text}
                style={{
                    width: width,
                    background: defaultBackgroundColor,
                    color: defaultColor,
                    borderRadius: defaultBorderRadius,
                    borderColor: defaultBorderColor,
                    borderWidth: defaultBorderWidth,
                    borderStyle: defaultBorderStyle,
                    whiteSpace: 'nowrap',
                    zIndex: '1',
                    fontSize: fontSize ? fontSize : '15px',
                    minWidth: 'fit-content',
                    height: defaultHeight,
                    padding: '10px 30px',
                    fontWeight: fontWeight
                }}
                disabled={disable}
            >
                {text}
            </button>
        </>
    );
};
export default Button;