import { useEffect, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useGetRequest } from "../ApiWrappers/useGetRequest";
import { v1 } from "src/Utils/ApiVersions";
export interface ITechincalDocument{
  displayName: string;
  sasToken: string;
  previewData: string;
}

export const useGetTechicalDocuments = () => {
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
  const scope = Config.GetConfigData().auraWebService?.scopes[0];
  const requestUrl = `${baseUrl}api/${v1}/downloads/technical-documents`;
  const [getDetails, data, loading, error] = useGetRequest<ITechincalDocument[]>()
  useEffect(() => {
     getDetails(scope, requestUrl)
  },[getDetails, requestUrl, scope])
    return { 
      getDetails,
      data,
      loading,
      error
   };
}
