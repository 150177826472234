import FlushAccordian from "src/Components/FlushAccordian/FlushAccordian";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { useEffect } from "react";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useGetTutorialVideos } from "src/Utils/Hooks/Downloads/useGetTutorialVideos";
import Loader from "src/Components/Loader/Loader";
import Document from "./../Documents/Document";
interface ITutorialVideosParams {
    setInfo: (message: string, type: InfoMessageType) => void;
}
const TutorialVideos: React.FC<ITutorialVideosParams> = ({ setInfo }) => {
    const {data, loading, error } = useGetTutorialVideos()

    useEffect(() => {
        if (error) {
            logger.logError(`Failed to fetch tutorial videos.`)
            setInfo(error, InfoMessageType.error);
        }
    }, [error, setInfo])

    return (
        <>
            {loading && <Loader />}
            {data &&
                <FlushAccordian title="Tutorial Videos" id='techVideos'>
                    <>
                        <div className="row" style={{ margin: 'auto' }}>
                            {data.map((video, i) => (
                                <div className="col-md-4 col-sm-12" style={{ padding: '0' }} key={i}>
                                    <div className="m-1">
                                        <Document key={i} image={video.previewData} title={video.displayName} fileLink={video.sasToken} duration={video.duration}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                </FlushAccordian>
            }
        </>
    )
}
export default TutorialVideos;