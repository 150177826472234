import { useEffect, useState } from "react";
import DocumentsDetails from "../Documents/DocumentsDetails";
import { ITechincalDocument } from "src/Utils/Hooks/Downloads/useGetTechicalDocuments";
import markeingMaterial from 'src/images/additional-marketing-material.png';

const MarketingMaterialDocuments: React.FC = () => {
    const [data, setData] = useState<ITechincalDocument[]>();

    useEffect(() => {
        var marketing_data:ITechincalDocument = {
            displayName: 'Additional Marketing Material',
            previewData: markeingMaterial,
            sasToken: 'https://bynder.hexagon.com/web/86e99b35d8feffb/aura-3d-imaging-system-customer-collateral'
    
        }
        setData([marketing_data])
    },[])
    return (
        <>
            {data &&
               <DocumentsDetails data={data} title="Marketing Material" id="marketing-material-doc" />
            }
        </>
    )
}
export default MarketingMaterialDocuments;