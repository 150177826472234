import { useIsAuthenticated } from "@azure/msal-react";
import Login from "../Authentication/Login";
import "./Header.css"
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import {useState } from "react";
import Profile from "../Profile/Profile";
import { useTranslation } from "react-i18next";
import logo from 'src/images/logo.svg';
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";

const Header = () => {
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();
  const [showDiv, setShowDiv] = useState<boolean>(false);
  const {clinicInfo } = useClinicContext();
  const {currentUser} = useClinicContext()
  const lastName = currentUser? currentUser.clinicUser.lastName : '';
  const firstName = currentUser? currentUser.clinicUser.firstName : '';
  
  return (
    <>
    {showDiv &&
     <Profile onClose= {()=>{setShowDiv(false)}}/>
    }
    <div className="container">
      <div className="row header-container">
        <div className="pt-2 col-md-3 col-lg-3 col-xl-3">
        <div style={{display: 'inline-block'}}> 
          <span className="px-3"><img src={logo} alt='logo'/></span>
          <span className="portal-name">{t('ClinicPortal')} </span>
        </div>
        </div>
        <div className="col-md-4 col-lg-5 col-xl-6">
          <div className="title-font" id="title">{clinicInfo?.name }</div>
        </div>
        {/* <div className="col-md-3 col-lg-2 col-xl-2 d-flex justify-content-end" >
          <ButtonWithIcon label="Go to App" icon="bi bi-box-arrow-right" testId="goToApp" onClick={() => window.location.href = "aura://"} backgroundColor='white' color='#A8927D' />
        </div> */}
        <div className="col-md-3 col-lg-1 col-xl-2 d-flex justify-content-end" style={{right:'24px', position : 'absolute'}}>
          {isAuthenticated ? (
            <div className="col-md-3 col-lg-1 col-xl-2 d-flex justify-content-end"  onClick={()=>{
              setShowDiv(true)
              }}>
            <ProfileIcon firstName={`${firstName}`} lastName={`${lastName}`} />
            </div>
            // <LogOut />
          ) : (
            <Login />
          )}
        </div>
        
      </div>
    </div>
  </>)
}
export default Header;