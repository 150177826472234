import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './DeviceInfo.css';
import Button from 'src/Components/Button/Button';
import MoreOptionsMenu from 'src/Components/MoreOptions/MoreOptions';
import { IDeviceDetails } from 'src/Utils/Hooks/ManageDevices/useGetDeviceInfo';
import { ILatestFirmware } from 'src/Utils/Hooks/ManageDeviceFirmware/GetFirmwareVersions';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import InstallUpdate from '../InstallUpdate/InstallUpdate';
import DeviceName from '../DeviceName/DeviceName';
import { useGetDeviceFwUpdateStatus } from 'src/Utils/Hooks/ManageDevices/useGetDeviceFwUpdateStatus';
import deviceIcon from 'src/images/device-icon.svg';
import CancelFwUpdate from '../CancelFwUpdate/CancelFwUpdate';
import { useDeviceDetails } from 'src/Utils/Hooks/ManageDevices/useDeviceDetails';
import { useTranslation } from 'react-i18next';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';
import divStyles from 'src/Utils/CssModules/div.module.css';

export interface IDeviceInfoParams {
    clinicId: string;
    clinicName: string;
    device: IDeviceDetails;
    firmwareVersionDetails: ILatestFirmware | undefined;
    distributorId: string;
    cmpVersions: (a: string, b: string) => number
    setInfo: (message: string, type: InfoMessageType) => void;
    setLoaderState: (load: boolean) => void;
}

const DeviceInfo: React.FC<IDeviceInfoParams> = ({ device, firmwareVersionDetails, clinicId, clinicName, distributorId, cmpVersions, setInfo, setLoaderState }) => {
    const [showInstallUpdates, setShowInstallUpdates] = useState(false);
    const [showRenameBox, setShowRenameBox] = useState(false);
    const [showFwCancelConfirmDialog, setshowFwCancelConfirmDialog] = useState<boolean>(false);
    const [updatedDeviceDetails, setUpdatedDeviceDetails] = useState<IDeviceDetails>(device);
    const {getFwUpdatesStatus, deviceFwDetails, deviceFwError} = useGetDeviceFwUpdateStatus();
    const { deviceFetchDetails, deviceFetchError, fetchDeviceInfoById } = useDeviceDetails();
    const { t } = useTranslation();
    
    const {currentUser} = useClinicContext()
    let Failed = 'failed';
    const requiredInstallUpdates = useCallback((deviceDetails: IDeviceDetails) => {
        if (deviceDetails?.firmwreVersionInfo.firmwareConfiguration?.version && firmwareVersionDetails) {
            if (!deviceDetails.firmwreVersionInfo.pendingFirmwareUpdate?.version) {
                // show install update button only firmwareConfiguration version < latest firmware version
                let val = cmpVersions(firmwareVersionDetails.version, deviceDetails.firmwreVersionInfo.firmwareConfiguration?.version);
                return val > 0 ? true : false;
            } else {
                // When update is initiated pendingFirmwareUpdate?.version is updated with latest version. then hide the update install button 
                let val = cmpVersions(deviceDetails.firmwreVersionInfo.pendingFirmwareUpdate?.version, firmwareVersionDetails.version) && cmpVersions(firmwareVersionDetails.version, deviceDetails.firmwreVersionInfo.firmwareConfiguration?.version);
                return val > 0 ? true : false;
            }
        }
        return false;
    }, [cmpVersions, firmwareVersionDetails])

    const requiredPendinglUpdates = useCallback((deviceDetails: IDeviceDetails) => {
        let res = deviceDetails?.firmwreVersionInfo.firmwareConfiguration?.version && deviceDetails.firmwreVersionInfo.pendingFirmwareUpdate?.version && cmpVersions(deviceDetails.firmwreVersionInfo.pendingFirmwareUpdate?.version, deviceDetails.firmwreVersionInfo.firmwareConfiguration?.version) > 0;
        return res || false;
    }, [cmpVersions])

    const [requiredUpdate, setRequiredUpdate] =  useState(false);
    const [pendingIstallation, setPendingInstallation] = useState(false);
    
    useEffect(() => {
        setRequiredUpdate(requiredInstallUpdates(device));
        setPendingInstallation(requiredPendinglUpdates(device));
      }, [device, requiredInstallUpdates, requiredPendinglUpdates]);

    useEffect(() => {
      if(pendingIstallation) {
        getFwUpdatesStatus(device.firmwreVersionInfo.deviceId);
      }
    },[pendingIstallation, device, getFwUpdatesStatus])

    useEffect(() => {
        if(deviceFwError !== ''){
            setInfo(deviceFwError, InfoMessageType.error)
        }
    }, [deviceFwError, setInfo])

    useEffect(() => {
        if(deviceFetchDetails){
            setUpdatedDeviceDetails(deviceFetchDetails);
            setRequiredUpdate(requiredInstallUpdates(deviceFetchDetails));
            setPendingInstallation(requiredPendinglUpdates(deviceFetchDetails));
        }
    }, [deviceFetchDetails, requiredInstallUpdates, requiredPendinglUpdates])

    useEffect(()=> {
        if(deviceFetchError !== ''){
            setInfo(deviceFetchError, InfoMessageType.error)
        }
    }, [deviceFetchError, setInfo])

    useEffect(() => {
        if(pendingIstallation && deviceFwDetails?.result !== Failed){
            const intervalId = setInterval(async () => {
                fetchDeviceInfoById(device.info);
                getFwUpdatesStatus(device.firmwreVersionInfo.deviceId);
            }, 5000);
    
            return () => {
                clearInterval(intervalId);
            };
        }
       
    }, [device.info, device.firmwreVersionInfo.deviceId, pendingIstallation, getFwUpdatesStatus, fetchDeviceInfoById, deviceFwDetails?.result, Failed]);

    const getColor = useMemo(() => {
        if(requiredUpdate && deviceFwDetails?.result !== Failed) {
            return '#A8927D';
        }
        else if(pendingIstallation) {
             if(deviceFwDetails?.result === Failed) {
                   return 'red';
             }
             else {
                return '#A8927D'
             }
        }
        return "black";
    },[deviceFwDetails, pendingIstallation, requiredUpdate, Failed])

    const getPendingStatus = useMemo(() => {
        if(deviceFwDetails?.result !== Failed) {
            return `...${t('UpdatingTo')} ${deviceFwDetails?.desiredFirmwareVersion}`;
        } 
        return `${t('UpdateDeviceErrorInfo', {latestFwVersion: deviceFwDetails?.desiredFirmwareVersion})}`
    },[deviceFwDetails?.result, deviceFwDetails?.desiredFirmwareVersion, Failed, t])
    
    const enableUpdateFirmware = () => {
        return requiredUpdate || deviceFwDetails?.result === Failed
    }

    return (
        <>
            {showInstallUpdates && firmwareVersionDetails &&
                <InstallUpdate
                    serialNumber={device.info.serialNumber}
                    deviceId={device.firmwreVersionInfo.deviceId}
                    latestFirmwareVersion={firmwareVersionDetails?.version}
                    setInfo={setInfo}
                    setLoaderState={setLoaderState}
                    closeDialog={() => setShowInstallUpdates(false)}
                />
            }
            {showFwCancelConfirmDialog &&
                <CancelFwUpdate
                    serialNumber={device.info.serialNumber}
                    closeDialog={() => setshowFwCancelConfirmDialog(false)}
                    deviceId={device.info.deviceId}
                    setInfo={setInfo}
                    setLoaderState={setLoaderState}
                    clinicId={clinicId}
                    latestFwVersion={deviceFwDetails?.desiredFirmwareVersion}
                />
            }
            <div className='row d-flex align-items-center device-row p-4 mt-4'>
                <div className='col-2 d-flex justify-content-center'>
                    <img src={deviceIcon} alt="deviceIcon" style={{width: '85px', height: '85px'}}/>
                </div>
                <div className='col-3'>
                    <DeviceName
                        serialNumber={device.info.serialNumber}
                        deviceId={device.info.deviceId}
                        clinicId={clinicId}
                        showRenameBox={showRenameBox}
                        closeRenameBox={() => { setShowRenameBox(false); }}
                        deviceName={device.info.name}
                        setLoaderState={setLoaderState}
                        setInfo={setInfo}
                    />
                    <div className='mt-1'>{t('Aura')}</div>
                    <div className='mt-1'>
                        {firmwareVersionDetails &&
                            requiredUpdate && !pendingIstallation && deviceFwDetails?.result !== Failed?
                            <Button
                                backgroundColor="#A8927D29"
                                color="#A8927D"
                                text={t("UpdateAvailable")}
                                borderColor="none"
                                fontSize="12px"
                                width='127px'
                                disable={!currentUser?.isClinicAdmin}
                                onClick={() => {setShowInstallUpdates(true);}}
                            />
                            :

                            pendingIstallation ?         
                                <Button
                                    backgroundColor={deviceFwDetails?.result === Failed? '#FF0F0014' :"#A8927D29"}
                                    color={getColor}
                                    text={getPendingStatus}
                                    fontSize="12px"
                                    onClick={() => { }}
                                />
                                 :
                                 <Button
                                 backgroundColor="none"
                                 color="black"
                                 text={t('YourDeviceIsUptoDate')}
                                 borderColor="#F8F8F8"
                                 borderWidth="2px"
                                 borderStyle="solid"
                                 borderRadius='24px'
                                 fontSize="12px"
                                 onClick={() => { }}
                             />                
                        }
                    </div>
                </div>
                <div className='col-2'>
                    <div className='serial-number text-center'>{device.info.serialNumber}</div>
                    <div className='text-center' style={{ fontSize: '12px' }}>{t('SerialNumber')}</div>
                </div>
                <div className='col-2'>
                    {pendingIstallation ?
                       deviceFwDetails?.result === Failed ?
                       <div className='text-center firmware-version' style={{ color: 'red' }}>{device.firmwreVersionInfo.firmwareConfiguration?.version}</div> :
                        <div className="spinner-border spinner-border-sm spinner-margin" role="status">
                            <span className="sr-only"></span>
                        </div> 
                        :
                        <div className='text-center firmware-version' style={{ color: getColor,  }}>{updatedDeviceDetails?.firmwreVersionInfo.firmwareConfiguration?.version}</div>
                    }
                    <div className='text-center' style={{ fontSize: '12px', color: getColor }}>{t('FirmwareVersion')} {pendingIstallation}</div>
                </div>
                <div className='col-2'></div>
                <div className='col-1 d-flex align-items-right moreOption-container'>
                    <MoreOptionsMenu id={`three-dots-${device.info.name}`}>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {firmwareVersionDetails &&
                                pendingIstallation && deviceFwDetails?.result !== Failed ?
                                <li><div  id="update" className={`dropdown-item ${!currentUser?.isClinicAdmin? divStyles.disabled : ''}`} key="update" onClick={() => { setshowFwCancelConfirmDialog(true) }}>{t('CancelUpdate')}</div></li> :
                                enableUpdateFirmware() && <li><div className= {`dropdown-item ${!currentUser?.isClinicAdmin? divStyles.disabled : ''}`} key="update" onClick={() => { setShowInstallUpdates(true) }}>{t('UpdateDevice')}</div></li>
                            } 
                            <li><div className="dropdown-item" id="rename" key="rename" onClick={() => { setShowRenameBox(true) }}>{t('RenameDevice')}</div></li>
                        </ul>
                    </MoreOptionsMenu>
                </div>
            </div>
        </>
    );
};

export default DeviceInfo;
