import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";

export interface IDeviceTwinResponse {
    deviceId:string,
    version:number,
    status:string,
    statusUpdatedTime:Date,
    connectionState:string,
    lastActivityTime:Date,
    cloudToDeviceMessageCount:number,
    tags:any,
    desiredProperties:any,
    reportedProperties:any
  }

export const useDeviceFirmwareUpdate = () => { 
  const [updateFwError, setUpdateFwError] = useState<any>('');
  const [updateFwLoading, setUpdateFwLoading] = useState<boolean>(false);
  const [updateFwResponse, setUpdateFwResponse] = useState<IDeviceTwinResponse>();
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
  const updateDeviceFirmware = useCallback((devieId: string, version:string) => {
    setUpdateFwLoading(true);
    setUpdateFwResponse(undefined);
    setUpdateFwError('');
    
    const requestUrl = `${baseUrl}api/${v1}/device/${devieId}/firmware-version`;
    logger.logInformation(`Request to Update device FW to ${version} for the device ${devieId} was made`);
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
        AuthConfig.getToken(scope).then(
            (token) => {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                axios
                    .put(requestUrl, {'version':version}, {headers})
                    .then(
                        (response) => {
                            setUpdateFwResponse(response.data);
                            setUpdateFwError('');
                            logger.logInformation(`Device ${devieId} is initiated with upgrade FW version ${version}`);
                        },
                        (error) => {     
                            const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                            logger.logError(`Failed to initaiate version: ${version} for the device ${devieId} and ${status}.`)                 
                            if(error.message !== '')  {
                                setUpdateFwError(error.message);
                            }
                            else{
                                setUpdateFwError(error);
                            }
                        }
                    )
                    .finally(() => {
                        setUpdateFwLoading(false);
                    });
            },
            (error) => {
                setUpdateFwLoading(false);
                setUpdateFwError(error);
                setUpdateFwResponse(undefined);
            }
        );
  },[baseUrl])

  return { updateDeviceFirmware, updateFwError, updateFwLoading, updateFwResponse };
  }

