import Licenses from "./Licenses/Licenses"
import { useCallback, useEffect, useRef, useState } from "react";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import Loader from "../Loader/Loader";
import { useGetLicensesInfo } from "src/Utils/Hooks/ManageLicenses/useGetLicensesInfo";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout";

const LicensesAndStorage = () => {
    const { licensesInfo, licenseInfoError, licensesInfoLoading, getLicencesInfo } = useGetLicensesInfo();
    const [infoBar, setInfoBar] = useState<boolean>(false);
    const [pageLoader, setPageLoader] = useState<boolean>(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const { setInfobarTimeout } = useInfobarTimeout();
    const clinicId = useRef<string>('')
    const updateList = useCallback(() => {
        getLicencesInfo(clinicId.current);
    }, [getLicencesInfo])
    useEffect(() => {
        if (licensesInfo) {
            clinicId.current = licensesInfo.clinicId;
        }
    }, [licensesInfo])
    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        if (messageType === InfoMessageType.none) {
            setInfoBar(false);
            return
        }
        if (messageType === InfoMessageType.success) {
            updateList();
        }
        msg.current = message;
        msgType.current = messageType;
        setInfoBar(true);
        setInfobarTimeout(() => setInfoBar(false));
    }, [updateList, setInfobarTimeout]);

    useEffect(() => {
        if (licenseInfoError !== '') {
            logger.logError(`Failed to fetch the license details`)
            setInfo(licenseInfoError, InfoMessageType.error);
        }
    }, [licenseInfoError, setInfo])

    useEffect(() => {
        setPageLoader(licensesInfoLoading)
    }, [licensesInfoLoading])

    return (
        <>
            {pageLoader && <Loader />}
            {infoBar && (
                <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                    <InfoBar
                        Message={msg.current}
                        MessageType={msgType.current}
                        closeClick={() => {
                            setInfoBar(false);
                        }}
                    />
                </div>
            )}
            {
                (licensesInfo &&
                    <Licenses
                        clinicId={licensesInfo.clinicId}
                        setInfo={setInfo}
                        updatePageLoader={setPageLoader}
                        updateList={updateList}
                        licensesInfo={licensesInfo} />)
            }
        </>
    )
}
export default LicensesAndStorage