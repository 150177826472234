import React from 'react';
import './MoreOptions.css';

interface IChildren {
    children: React.ReactNode;
    disabled?: boolean;
    id?: string;
}
const MoreOptionsMenu: React.FC<IChildren> = ({children, id, disabled}) => {
    return (
        <div className={`moreButton ${disabled ? 'disabled' : ''}`}  id={id}>
            <div className="row menu-icon py-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <i className="bi bi-three-dots" style={{fontSize: '24px'}}></i>
            </div>
            {children} 
        </div>
    );
};

export default MoreOptionsMenu;
