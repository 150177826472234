import './GridView.css'
export interface IGridViewParams {
    title: string
    headerTextRight?: string,
    children: React.ReactNode,
}
const GridView: React.FC<IGridViewParams> = ({children, headerTextRight, title}) => {
    return (
        <>
            <div className='row d-flex mt-4 gridview'>
                <div className='m-2'>
                    <div className="m-3">
                        <span className='gridview-header row'>
                            <div className='col-8'>
                                <b>{title}</b>
                            </div>
                            
                            {headerTextRight && (
                                <div className='extra-text-right col-4 d-flex justify-content-end'>
                                    {headerTextRight}
                                </div>
                            )}
                        </span>
                    </div>

                    <hr style={{ color: '#A8927D', width:'95%', marginLeft:'15px' }} />
                    <div className="m-3">
                        {children}
                    </div>
                </div>

            </div>
        </>
    )
}
export default GridView; 