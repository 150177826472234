import { useEffect, useState } from "react";
import Dialog from "src/Components/Dialog/Dialog";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import './AddNewLicence.css'
import Button from "src/Components/Button/Button";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { ILicenceRequest, LicenceType, useActivateLicence } from "src/Utils/Hooks/ManageLicenses/useActivateLicence";
import ActivateLicenseSuccessInstruction from "../ActivateLicenceSuccessInstruction/ActivateLicenseSuccessInstruction";
import { useTranslation } from "react-i18next";
interface IAddNewLicenceParams {
  clinicId: string;
  setInfo: (message: string, type: InfoMessageType) => void;
  onClose: () => void;
  updateList: () => void;
  updatePageLoader: (load: boolean) => void;
}
const AddNewLicence: React.FC<IAddNewLicenceParams> = ({ clinicId, setInfo, updateList, updatePageLoader, onClose }) => {
  const { activateNewClinicLicence, reqestError, reqestLoading, reqestResponse } = useActivateLicence()
  let req: ILicenceRequest = {
    entitlementId: '', licenceType: LicenceType.userLicence
  }
  const [activateLicense, setActivateLicense] = useState<ILicenceRequest>(req);
  const [display, setdisplay] = useState('block');
  const [showSuccessMessage, setShowSuccessMsg] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (reqestResponse) {
      logger.logInformation(`License is added successfully. Entitlement Id is ${activateLicense.entitlementId}, clinicId is ${clinicId}.`)
      setShowSuccessMsg(true);
    }
    else {
      if (reqestError) {
        logger.logError(`Error occur while adding license. EntitlementId is ${activateLicense.entitlementId}, clinicId is ${clinicId}.`)
        setInfo(reqestError, InfoMessageType.error);
        onClose();
      }
    }

  }, [reqestResponse, activateLicense.entitlementId, clinicId, setInfo, onClose, reqestError])
  useEffect(() => {
    updatePageLoader(reqestLoading);
  }, [reqestLoading, updatePageLoader])
  const addNewLicense = () => {
    setInfo('', InfoMessageType.none); 
    activateNewClinicLicence(activateLicense, clinicId);
    setdisplay('none');
  }
  return (
    <div>
      {
        showSuccessMessage ?
          <ActivateLicenseSuccessInstruction onClose={() => { onClose(); updateList(); }} /> :
          <div style={{ display: display }}>
            <Dialog
              title={t('AddNewLicence')}
              headerAlign='left'
              submitText=""
              onClose={onClose}
              onSubmit={() => { }}
              disableSubmit={true}
              hideSubmit={true}
            >
              <div className="label">
                {t('EntitlementId')}
              </div>
              <div className="row mt-2">
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control input-box"
                    data-testid="license"
                    onChange={(e) => {
                      setActivateLicense({ ...activateLicense, entitlementId: e.target.value })
                    }}
                  />
                </div>
                <div className="col-4">
                  <Button
                    backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                    color='white'
                    testId='ok'
                    onClick={() => { addNewLicense() }}
                    text={t('Enter')}
                    height="48px"
                    width="182px"
                    borderRadius='24px'
                    disable={!activateLicense.entitlementId}
                  />
                </div>
              </div>
            </Dialog>
          </div>
      }
    </div>
  )
}
export default AddNewLicence;