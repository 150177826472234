import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import { useEffect, useState } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import axios from 'axios';

export interface IAppleAppVersionInfo{
    date: string;
    version: string;
    releaseNotesUrl: string;
    appSetUpUrl: string;
}

export interface IWindowsAppVersionInfo{
    date: string;
    version: string;
    releaseNotesUrl: string;
    appSetUpUrl: string;
}

export interface IAppReleaseInfo {
    macOSAppRelease: IAppleAppVersionInfo;
    windowsAppReleases: IWindowsAppVersionInfo[];
    iosAppRelease: IAppleAppVersionInfo;
}
export enum OSType {
    Windows = 'windows',
    MacOS = 'macOS',
    IOS = 'iOS'
}
export const useGetAppReleasesInfo = () => {
    const [appReleasesInfo, setAppReleasesInfo] = useState<IAppReleaseInfo>()
    const [appReleasesInfoLoading, setAppReleasesInfoLoading] = useState(false)
    const [appReleasesInfoError, setAppReleasesInfoError] = useState('')
    useEffect(() => {
        setAppReleasesInfoLoading(true);
        setAppReleasesInfoError('');
        const scope = Config.GetConfigData().auraWebService?.scopes[0];
        const baseUrl = Config.GetConfigData().serviceUrl;
        const windowsAppReleasesCount = 5;
        const requestUrl = `${baseUrl}api/v1/app-releases?windowsAppReleasesCount=${windowsAppReleasesCount}`;
        AuthConfig.getToken(scope).then(
            (success) => {
                const headers = {
                    Authorization: `Bearer ${success}`,
                };
                logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
                axios
                    .get(requestUrl, { headers })
                    .then(
                        (response) => {
                            setAppReleasesInfo(response.data);
                            const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                            logger.logInformation(`Get request to ${requestUrl} for app releases details was successful and ${status}.`);
                            setAppReleasesInfoError('');
                        },

                        (error) => {
                            const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                            logger.logError(`Error occured to fetch app releases details for ${requestUrl}: ${status}.`)
                            if (error.message && error.message !== '') {
                                setAppReleasesInfoError(error.message);
                            } else {
                                setAppReleasesInfoError(error.response.data);
                            }
                            setAppReleasesInfo(undefined);
                        }
                    )
                    .finally(() => {
                        setAppReleasesInfoLoading(false);
                    });
            },
            (error) => {
                setAppReleasesInfoLoading(false);
                setAppReleasesInfoError(error);
            }
        );
    }, [])

    return { appReleasesInfo, appReleasesInfoError, appReleasesInfoLoading };
};
