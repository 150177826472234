import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useDeviceRename = () => { 
  const [deviceRenameError, setDeviceRenameError] = useState<any>('');
  const [deviceRenameLoading, setDeviceRenameLoading] = useState<boolean>(false);
  const [deviceRenameResponse, setDeviceRenameResponse] = useState<boolean>(false);
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);

  const renameDevice = useCallback((deviceId: string, clinicId: string, deviceName:string) => {
    setDeviceRenameLoading(true);
    setDeviceRenameResponse(false);
    setDeviceRenameError('');
    
    const requestUrl = `${baseUrl}api/${v1}/device/${deviceId}/clinic/${clinicId}`;
    logger.logInformation(`Request to rename ${deviceName} for the device ${deviceId} and given clinic ${clinicId} was made`);
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
        AuthConfig.getToken(scope).then(
            (token) => {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                axios
                    .put(requestUrl, {'name':deviceName}, {headers})
                    .then(
                        (response) => {
                            setDeviceRenameResponse(true);
                            setDeviceRenameError('');
                            logger.logInformation(`Device ${deviceId} is renamed to ${deviceName} for the given clinic ${clinicId}`);
                        },
                        (error) => {    
                            const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                            logger.logError(`Failed to rename: ${deviceName} for the device ${deviceId} and given clinic ${clinicId}: ${status}.`)
                            if(error.message !== '')  {
                                setDeviceRenameError(error.message);
                            }
                            else{
                                setDeviceRenameError(error);
                            }
                        }
                    )
                    .finally(() => {
                        setDeviceRenameLoading(false);
                    });
            },
            (error) => {
                setDeviceRenameLoading(false);
                setDeviceRenameError(error);
                setDeviceRenameResponse(false);
            }
        );
  },[baseUrl])

  return { renameDevice, deviceRenameError, deviceRenameLoading, deviceRenameResponse };
  }

