import Button from 'src/Components/Button/Button';
import './AppReleases.css';
import arrowSquareIcon from "src/images/arrowSquare-icon.svg";
import { useTranslation } from 'react-i18next';
import { OSType } from 'src/Utils/Hooks/ManageAppReleases/useGetAppReleasesInfo';
import { logger } from 'src/Utils/LoggerService/LoggerService';

interface IAppDownloads {
    osType: OSType;
    version?: string;
    date: string;
    image: string;
    title: string;
    releaseNotesUrl: string;
    appSetupUrl: string;
}

const AppReleases: React.FC<IAppDownloads> = ({ osType, version, date, image, title, appSetupUrl, releaseNotesUrl }) => {
    const { t } = useTranslation();
    const formatUtcDate = (date: string) => {
        const releaseDate = new Date(date);
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let month = months[releaseDate.getMonth()];
        let day = releaseDate.getDate().toString().padStart(2, "0");;
        let year = releaseDate.getFullYear();
        return `${day} ${month} ${year}`;
    }
    return (
        <>
            <div className="row mt-4 py-2 appDownload">
                <div className="m-3">
                    <div className="row">
                        <div className='col-12 d-flex justify-content-center'>
                            <b>{title}</b>
                        </div>
                        <div className='col-12 mt-4 d-flex justify-content-center'>
                            <img src={image} alt="osIcon" />
                        </div>
                        <div className='col-12 mt-4 pt-1 d-flex justify-content-center'
                        onClick={() => {logger.logInformation(`User downloaded release notes for "${osType}" with version ${version}`)}}>
                            <a
                                href={releaseNotesUrl}
                                style={{ cursor: 'pointer', display: 'block' }}
                                target="_blank"
                                rel="noOpener noReferrer"
                            >
                                <span className='version'>
                                   {version?  `${t('Version')} ${version}` : 'Release notes'}
                                </span>
                                <img src={arrowSquareIcon} alt='arrow-square-icon' />
                            </a>
                        </div>
                        <div className='col-12 mt-2 date d-flex justify-content-center'>
                            {formatUtcDate(date)}
                        </div>
                        <div className='col-12 mt-4 d-flex justify-content-center'>
                            <a
                                href={appSetupUrl}
                                target={(osType === OSType.Windows) ? "" : '_blank'}
                                rel="noOpener noReferrer"
                                style={{ cursor: 'pointer', display: 'block' }}
                            >
                                {(osType === OSType.Windows) ?
                                    <Button
                                        backgroundColor="#A8927D29"
                                        color="#A8927D"
                                        text={t('Download')}
                                        borderColor="none"
                                        fontSize="13px"
                                        width='127px'
                                        disable={!appSetupUrl} 
                                        onClick={() => { logger.logInformation(`User downloaded ${(osType)} app with version: ${version}`)}}
                                    /> :
                                    <Button
                                        backgroundColor="#A8927D29"
                                        color="#A8927D"
                                        text={t('AppStore')}
                                        borderColor="none"
                                        fontSize="13px"
                                        width='127px'
                                        disable={!appSetupUrl} 
                                        onClick={() => {logger.logInformation(`User accessed ${(osType)} app from portal.`)} }
                                    />
                                }
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AppReleases