import { useEffect } from "react"
import { InfoMessageType } from "src/Components/InfoBar/InfoBar"
import { useDeviceFirmwareUpdate } from "src/Utils/Hooks/ManageDevices/useDeviceFirmwareUpdate";
import UpdateInstallInstructions from "../UpdateInstallInstructions/UpdateInstallInstructions";
import SmallDialog from "src/Components/Dialog/SmallDialog";
import React from "react";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";
interface IInstallUpdate {
    serialNumber:string,
    deviceId: string,
    latestFirmwareVersion: string,
    setInfo: (message: string, type: InfoMessageType) => void;
    setLoaderState: (load: boolean) => void;
    closeDialog: () => void;
}


const InstallUpdate: React.FC<IInstallUpdate> = ({deviceId, serialNumber, latestFirmwareVersion, setInfo, setLoaderState, closeDialog}) => {
    const { updateDeviceFirmware, updateFwError, updateFwLoading, updateFwResponse } = useDeviceFirmwareUpdate();
    const { t } = useTranslation();
    useEffect(() => {
        if (updateFwResponse) {
            closeDialog()
            let successMsg = t('UpdateDeviceSuccessMsg', {serialNumber: serialNumber, latestFwVersion: latestFirmwareVersion});
            logger.logInformation(successMsg + ` Device Id is ${deviceId}.`);
            setInfo(successMsg, InfoMessageType.success);
        }
        else {
            if (updateFwError) {
                closeDialog()
                logger.logError(`Failed to update device ${deviceId} with serial number ${serialNumber} to firware version ${latestFirmwareVersion}`)
                setInfo(updateFwError, InfoMessageType.error);
            }
        }

    }, [updateFwResponse, updateFwError, serialNumber, setInfo, deviceId, latestFirmwareVersion, closeDialog, t])

    useEffect(() => {
        setLoaderState(updateFwLoading);
    }, [updateFwLoading, setLoaderState])

    return (
        <>
            
                <SmallDialog
                submitButtonText="Update"
                    title='FirmwareUpdate'
                    onClose={() => { closeDialog() }}
                    onSubmit={() => {
                        
                        updateDeviceFirmware(deviceId,latestFirmwareVersion)
                    }} >
                    <UpdateInstallInstructions />
                </SmallDialog>
           
        </>
    )
}

export default  InstallUpdate;