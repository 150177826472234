import Loader from "../Loader/Loader";
import { useCallback, useEffect, useRef, useState } from "react";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import { IDeviceDetails, useGetDeviceInfo } from 'src/Utils/Hooks/ManageDevices/useGetDeviceInfo';
import { useGetFirmwareVersion } from 'src/Utils/Hooks/ManageDeviceFirmware/GetFirmwareVersions';
import DeviceInfo from '../Device/DeviceInfo/DeviceInfo';
import './Device.css';
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout";
import { useGetClinicInfo } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";

const Device = () => {
    const [infoBar, setInfoBar] = useState<boolean>(false);
    const { deviceInfo, deviceInfoLoading, deviceInfoError } = useGetDeviceInfo();
    const { getClinicInfo, clinicError, clinicLoading } = useGetClinicInfo()
    const { cmpVersions, firmwareVersionDetails, firmwareVersionError, firmwareVersionLoading } = useGetFirmwareVersion()
    const [load, setLoad] = useState(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const { setInfobarTimeout } = useInfobarTimeout();

    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        setInfoBar(true);
        msg.current = message;
        msgType.current = messageType;
        setInfobarTimeout(() => setInfoBar(false));
    }, [setInfobarTimeout]);

    const updateList = useCallback((message: string, messageType: InfoMessageType) => {
        setInfo(message, messageType);
        if(messageType === InfoMessageType.success){
            getClinicInfo();
        }
    }, [setInfo, getClinicInfo])

    const sortDevices = (list: IDeviceDetails[]) => {
        list.sort((a, b) => {
          if (a.info.name === null || a.info.name === undefined) return 1;
          if (b.info.name === null || b.info.name === undefined) return -1;
          return a.info.name.localeCompare(b.info.name);
        })
        return list;
    }


    useEffect(() => {
        const errors = [clinicError, deviceInfoError, firmwareVersionError];
        errors.forEach(error => {
            if (error) {
                setInfo(error, InfoMessageType.error);
            }
        });
    }, [clinicError, deviceInfoError, firmwareVersionError, setInfo]);
    
    useEffect(() => {
        setLoad(deviceInfoLoading || firmwareVersionLoading || clinicLoading)  
    }, [deviceInfoLoading, firmwareVersionLoading, clinicLoading])

    return (
        <>
            {load && <Loader />}
            {infoBar && (
                <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                    <InfoBar
                        Message={msg.current}
                        MessageType={msgType.current}
                        closeClick={() => {
                            setInfoBar(false);
                        }}
                    />
                </div>
            )}
            
                {deviceInfo?.deviceDetails && sortDevices(deviceInfo?.deviceDetails)
                .map((item) => (
                    <DeviceInfo
                        clinicId={deviceInfo.clinicId}
                        clinicName={deviceInfo.clinicName}
                        distributorId={deviceInfo.distributorDetails.distributorId}
                        device={item}
                        key={item.info.deviceId}
                        firmwareVersionDetails={firmwareVersionDetails}
                        cmpVersions={cmpVersions}
                        setInfo={updateList}
                        setLoaderState={setLoad} />
                ))}
            
        </>
    )
}
export default Device;