import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { usePutRequest } from "../ApiWrappers/usePutRequest";

export interface ICreateClinicRequest {
    name: string,
    email: string,
    contactNumber: string,
    address: string
}

export const useEditClinic = () => {
    const { putRequest, reqestError, reqestLoading, reqestResponse } = usePutRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);

    const editClinic = useCallback((req: ICreateClinicRequest, clinicId: string) => {
        const requestUrl = `${baseUrl}api/v1/clinic/${clinicId}`;
        logger.logInformation(`Request to edit clinic ${clinicId} with request data ${JSON.stringify(req)} was made`)
        putRequest<ICreateClinicRequest>(req, requestUrl);
    }, [baseUrl, putRequest]);

    return { editClinic, reqestResponse, reqestLoading, reqestError};
};
