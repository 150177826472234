import { INavbarItem } from "./NavBar";

export const TopItems: INavbarItem[] =  [
    
    {
        id:'clinicprofile',
        name:'ClinicProfile',
        hasChildren: false,
        routePath:'/clinicprofile',
        testid:'clinicprofile'
    },
    {
        id:'users',
        name:'Users',
        hasChildren: false,
        routePath:'/users',
        testid:'users'
    },
    {
        id:'device',
        name:'CaptureDevices',
        hasChildren: false,
        routePath:'/device',
        testid:'device'
    },
    {
        id:'licence',
        name:'Licences',
        hasChildren: false,
        routePath:'/licences',
        testid:'licence'
    },
    {
        id:'app',
        name:'App',
        hasChildren: false,
        routePath:'/app',
        testid:'app'
    },
    {
        id:'downloads',
        name:'Downloads',
        hasChildren: false,
        routePath:'/downloads',
        testid:'downloads'
    }
]
  