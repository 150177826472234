import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

const LogOut = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    return (
        <div style={{ textAlign: 'center' }}>
            <span onClick={() => { instance.logoutRedirect(); }} id="logout" style={{ color: '#A8927D', fontSize: '15px', fontWeight: '500', cursor: 'pointer' }}>
                {t("LogOut")}
            </span>
        </div>
    )
}
export default LogOut;