import { useTranslation } from 'react-i18next';
import './Loader.css'
type AppProps = {
  message: string;
};
const Loader = ({ message }: AppProps) => {
  const { t } = useTranslation();
  return (
    <div className='loader'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        color: 'black',
        background: '#f5f2f0',
        zIndex: 1000,
        position: 'absolute',
        top: '200px'
      }}
    >
      <div className="spinner-border  black" role="status">
        {/* <span className="sr-only">Loading...</span> */}
      </div>
      <div className='mt-5 pt-5' style={{position: 'absolute', paddingLeft: '15px', paddingTop: '3vw'}}>
        {t(message)}
      </div>
    </div>
    //  Simlar to default props
    //  <div class="ui big text loader">{props.message || "Loading..."}</div>
  );
};
Loader.defaultProps = {
  message: 'Loading',
};
export default Loader;
