import React from 'react';
import './DropDown.css';
import { useTranslation } from 'react-i18next';
import divStyles from 'src/Utils/CssModules/div.module.css';

interface ISelectControlData {
  // Selected Item which will be shown by default
  selectedItem: string;

  // List of Options to be shown on drop down
  items: string[];

  // Callback method on change
  onSelectionChange: (item: string) => void;
  id?: string;
  disabled?: boolean
}
const DropDown: React.FC<ISelectControlData> = ({
  selectedItem,
  items,
  onSelectionChange,
  id = 'selectId',
  disabled
}) => {
  const onChangeSelection = (item: string) => {
      onSelectionChange(item);
  };
  const { t } = useTranslation();

  return (
    <div className={`dropdown ${disabled? divStyles.disabled : ''}`}>
      <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"  aria-expanded="false">
        <span>{t(selectedItem)}</span>
        <i className="bi bi-chevron-down ml-auto"></i>
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {items.map((item, index) => (
          item !== selectedItem &&
          <li className="dropdown-item" key={index} id={item} onClick={() => onChangeSelection(item)}>
            {t(item)}
          </li>
        ))}
      </div>
    </div>
  )
};
export default React.memo(DropDown);
