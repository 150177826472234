import axios from "axios";
import { useCallback, useState } from "react"
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";
export enum AdminRoleOPeration {
  Add,
  Remove
}
interface IManageRoles {
  email: string,
  clinicId: string;
  roleOperation: AdminRoleOPeration
}
export const useGetManageUserRoles = () => {
  const [manageRolesStatus, setManageRolesSetStatus] = useState(false);
  const [manageRolesError, setManageRolesError] = useState('')
  const [manageRolesLoading, setManageRolesLoading] = useState(false)

  const updateRoles = useCallback((request: IManageRoles) => {
    setManageRolesLoading(true);
    setManageRolesError('');
    setManageRolesSetStatus(false);
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;
    const rolesUrl = `${baseUrl}api/${v1}/clinic/${request.clinicId}`
    const requestUrl = request.roleOperation === AdminRoleOPeration.Add ? `${rolesUrl}/make-admin` : `${rolesUrl}/remove-admin`

    let role = AdminRoleOPeration.Add ? 'admin' : 'member'
    logger.logInformation(`Request to change user role to ${role} for given user ${request.email} and clinic id: ${request.clinicId} was made`);

    AuthConfig.getToken(scope).then(
      (success) => {
        const headers = {
          Authorization: `Bearer ${success}`,
          'Content-Type': 'application/json',
        };
        axios
          .put(requestUrl, request.email, { headers })
          .then(
            (response) => {
              setManageRolesSetStatus(true);
              setManageRolesError('');
              logger.logInformation(`User role is changed to ${role} for given user ${request.email} and clinic id: ${request.clinicId}.`)
            },

            (error) => {
              setManageRolesSetStatus(false);
              const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
              logger.logError(`Failed to change user role to ${role} for given user ${request.email} and clinic id: ${request.clinicId}. Error details for ${requestUrl}: ${status}.`)

              if (error.response && error.response.data !== '') {
                if (error.response.data.detail !== '' && error.response.data.detail !== undefined) {
                  setManageRolesError(error.response.data.detail);
                } else {
                  setManageRolesError(error.response.data);
                }
              }
              else if (error.message !== '') {
                setManageRolesError(error.message);
              }
              else {
                setManageRolesError(error);
              }
            }
          )
          .finally(() => {
            setManageRolesLoading(false);
          });
      },
      (error) => {
        setManageRolesLoading(false);
        setManageRolesError(error);
      }
    );
  }
    , [])
  return { updateRoles, manageRolesStatus, manageRolesError, manageRolesLoading };
}