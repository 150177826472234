import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { useEffect } from "react";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useGetTechicalDocuments } from "src/Utils/Hooks/Downloads/useGetTechicalDocuments";
import DocumentsDetails from "../Documents/DocumentsDetails";
import Loader from "src/Components/Loader/Loader";
interface ITechnicalDocumentsParams {
    setInfo: (message: string, type: InfoMessageType) => void;
}

const TechnicalDocuments: React.FC<ITechnicalDocumentsParams> = ({setInfo }) => {
    const { data, loading, error } = useGetTechicalDocuments()

    useEffect(() => {
        if (error) {
            logger.logError(`Failed to get techinical documents.`)
            setInfo(error, InfoMessageType.error);
        }
    }, [error, setInfo])
    return (
        <>
            {loading && <Loader />}
            {data &&
               <DocumentsDetails data={data} title="Technical Documentation" id="tech-doc"/>
            }
        </>
    )
}
export default TechnicalDocuments;