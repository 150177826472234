import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDeleteUser } from "src/Utils/Hooks/GetClinicInfo/useDeleteUser";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { logger } from "src/Utils/LoggerService/LoggerService";
import SmallDialog from "src/Components/Dialog/SmallDialog";

interface IDeleteUserProps {
    userId: string;
    userName: string;
    clinicId: string;
    setLoaderState: (load: boolean) => void;
    setInfo: (message: string, type: InfoMessageType) => void;
    updateUsers: () => void;
    onClose: () => void;
}

const DeleteUser: React.FC<IDeleteUserProps> = ({ userId, clinicId, userName, setInfo, updateUsers, setLoaderState, onClose }) => {
    const { deleteClinicUser, deleteUserResponse, deleteUserError, deleteUserLoading } = useDeleteUser();
    const { t } = useTranslation();

    useEffect(() => {
        setLoaderState(deleteUserLoading);
    }, [deleteUserLoading, setLoaderState])


    useEffect(() => {
        if (deleteUserResponse) {
            logger.logInformation(`User is removed successfully. UserId is ${userId}, clinicId is ${clinicId}.`);
            setInfo(t('UserRemovedSuccessMsg', {userName: userName}), InfoMessageType.success);
            updateUsers();
            onClose();
        } else if (deleteUserError) {
            logger.logError(`Error occur while removing user from clinic. UserId is ${userId}, clinicId is ${clinicId}.`);
            setInfo(deleteUserError, InfoMessageType.error);
            onClose();
        }
    }, [deleteUserResponse, deleteUserError, userId, userName, clinicId, setInfo, updateUsers, onClose, t]);

    const handleDeleteClick = () => {
        deleteClinicUser(userId, clinicId);
    };

    return (
        <>
            <SmallDialog
                submitButtonText={t("Delete")}
                title={t('DeletingUser')}
                submitButtonColor="var(--Score-5--Red, #FF0F00)"
                cancelButtonColor="black"
                onClose={onClose}
                onSubmit={handleDeleteClick} >
                <div className="mt-2" style={{ textAlign: 'center' }}>
                    <Trans i18nKey='DeleteUserInstruction' values={{userName: userName}}/>
                </div>
            </SmallDialog>
        </>
    );
};

export default DeleteUser;
