import { useMsal } from "@azure/msal-react";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";

const Login = () => {
  const { instance } = useMsal();
  return (
    <div style={{ paddingRight: '5vw' }}>
      <ButtonWithIcon
        label="Log In"
        icon="bi bi-box-arrow-right"
        onClick={() => { instance.loginRedirect(); }}
        backgroundColor='white'
        testId="login"
        color='#A8927D' />
    </div>
  )
}
export default Login;