

import { useEffect, useMemo, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import { IDevice, IDistributor } from '../GetClinicInfo/useGetClinicInfo';
import { useDeviceDetails } from './useDeviceDetails';
import { useClinicContext } from '../ClinicContext';
export interface IFirmwareConfiguration {
    version: string,
    bleInterfaceVersion: string,
    captureProtocolVersion: string,
    appVersion: string,
    osVersion: string
}
export interface IPendingFirmwareUpdate {
    requestId:string,
    version:string,
    initiatedDate:string
}
export interface IFirmwareVersions {
    deviceId: string;
    name: string;
    state: string;
    firmwareConfiguration?: IFirmwareConfiguration
    pendingFirmwareUpdate?: IPendingFirmwareUpdate
}
export interface IDeviceDetails
{
    firmwreVersionInfo: IFirmwareVersions;
    info:IDevice
}
export interface IDeviceInfo {
    clinicId: string;
    clinicName: string;
    deviceDetails?: IDeviceDetails[]
    distributorDetails: IDistributor,
}
export const useGetDeviceInfo = () => {
    const [deviceInfo, setDeviceInfo] = useState<IDeviceInfo>()
    const [deviceInfoLoading, setDeviceInfoLoading] = useState(false)
    const [deviceInfoError, setDeviceInfoError] = useState('')
    const scope = useMemo(() => Config.GetConfigData().auraWebService?.scopes[0], []);
    const {clinicInfo } = useClinicContext();
    const { fetchDeviceDetails } = useDeviceDetails();

    useEffect(() => {
        if (clinicInfo) {
            setDeviceInfoLoading(true);
            AuthConfig.getToken(scope).then(
                (token) => {
                    if (clinicInfo.devices.length === 0) {
                        let deviceInfo: IDeviceInfo = {
                            clinicId: clinicInfo?.clinicId,
                            clinicName: clinicInfo?.name,
                            distributorDetails: clinicInfo?.distributorDetail
                        };
                        setDeviceInfo(deviceInfo);
                        setDeviceInfoLoading(false)
                    } else {
                    const promises = clinicInfo.devices?.map((device) => fetchDeviceDetails(device, token));
                    Promise.all(promises).then(
                        (success) => {
                            let deviceInfo: IDeviceInfo = {
                                clinicId: clinicInfo?.clinicId,
                                deviceDetails: success,
                                clinicName: clinicInfo?.name,
                                distributorDetails: clinicInfo?.distributorDetail
                            };
                            setDeviceInfo(deviceInfo);
                        },
                    ).finally(() =>setDeviceInfoLoading(false))
                    }
                },
                (error) => { 
                    setDeviceInfoError(error)
                }
            )
        }
    }, [clinicInfo, fetchDeviceDetails, scope])


    return { deviceInfo, deviceInfoLoading, deviceInfoError };
};
