import { MsalProvider } from '@azure/msal-react';
import './App.css';
import { AuthConfig } from './AuthConfig';
import AppLayout from './Components/AppLayout/AppLayout';
import TelemetryProvider from './Utils/LoggerService/LoggerProvider';

const App = () => {
  
  return (
    <>
      <TelemetryProvider>
        <MsalProvider instance={AuthConfig.getMsalInstance()}>
            <AppLayout />
        </MsalProvider>
      </TelemetryProvider>
    </>
  );
};
export default App;
