import { useTranslation } from "react-i18next";

const CancelFwUpdateInstructions:React.FC = () => {
    const { t } = useTranslation();
    return (
        <>
           <div className="mt-1">
                <p style={{ textAlign: 'center', marginBottom:'0px', fontSize:'14px', fontWeight:'400' }}>
                {t('CancelFwUpdateInstruction')}</p>
                
            </div>
        </>
    )
}
export default CancelFwUpdateInstructions;