import AppReleases from "./AppReleases/AppReleases";
import windowsIcon from "src/images/windows-icons.svg";
import macosIcon from "src/images/macos-icon.svg";
import iosIcon from "src/images/ios-icon.svg";
import Accordion from "../Accordion/Accordion";
import { useTranslation } from "react-i18next";
import WindowsAppReleaseHistory from "./WindowsAppReleaseHistory/WindowsAppReleaseHistory";
import { OSType, useGetAppReleasesInfo } from "src/Utils/Hooks/ManageAppReleases/useGetAppReleasesInfo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import Loader from "../Loader/Loader";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";

const App = () => {
    const { t } = useTranslation();
    const { appReleasesInfo, appReleasesInfoError, appReleasesInfoLoading } = useGetAppReleasesInfo();
    const [infoBar, setInfoBar] = useState<boolean>(false);
    const [pageLoader, setPageLoader] = useState<boolean>(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const macOSAppSetupUrl = Config.GetConfigData().appSetupUrls.macOS;
    const iOSAppSetupUrl = Config.GetConfigData().appSetupUrls.iOS;
    const kolAppSetUpUrl = Config.GetConfigData().kolAppSetupUrls;
    const { setInfobarTimeout } = useInfobarTimeout();
    const {clinicInfo} = useClinicContext();
    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        setInfoBar(true);
        msg.current = message;
        msgType.current = messageType;
        setInfobarTimeout(() => setInfoBar(false));
    }, [setInfobarTimeout]);


    useEffect(() => {
        setPageLoader(appReleasesInfoLoading)
    }, [appReleasesInfoLoading])

    useEffect(() => {
        if (appReleasesInfoError !== '') {
            setInfo(appReleasesInfoError, InfoMessageType.error)
        }
    }, [appReleasesInfoError, setInfo])

    const isKolClinic = useMemo(() =>  clinicInfo && clinicInfo.isKol, [clinicInfo]);
    return (
        <>
            {pageLoader && <Loader />}
            {infoBar && (
                <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                    <InfoBar
                        Message={msg.current}
                        MessageType={msgType.current}
                        closeClick={() => {
                            setInfoBar(false);
                        }}
                    />
                </div>
            )}
            <div className="row">
                {
                   appReleasesInfo?.windowsAppReleases && appReleasesInfo.windowsAppReleases.length > 0 &&
                    <div className="col" style={{marginRight: '3%', maxWidth: '32%'}}>
                        <AppReleases
                            title={`${t('AuraFor')} ${OSType.Windows}`}
                            osType={OSType.Windows}
                            version={appReleasesInfo.windowsAppReleases[0].version}
                            date={appReleasesInfo.windowsAppReleases[0].date}
                            image={windowsIcon}
                            releaseNotesUrl={appReleasesInfo.windowsAppReleases[0].releaseNotesUrl}
                            appSetupUrl={appReleasesInfo.windowsAppReleases[0].appSetUpUrl} />
                    </div>
                }
                {
                    appReleasesInfo?.macOSAppRelease && appReleasesInfo.windowsAppReleases.length > 0 && 
                    <div className="col" style={{marginRight: '3%', maxWidth: '32%'}}>
                        <AppReleases
                            title={`${t('AuraFor')} ${OSType.MacOS}`}
                            osType={OSType.MacOS}
                            date={appReleasesInfo.windowsAppReleases[0].date} // this to be reverted once VENAU-8090 is fixed
                            image={macosIcon}
                            appSetupUrl={isKolClinic ? kolAppSetUpUrl.macOS : macOSAppSetupUrl}
                            version={appReleasesInfo.windowsAppReleases[0].version} //  this to be reverted once VENAU-8090 is fixed
                            releaseNotesUrl={appReleasesInfo.windowsAppReleases[0].releaseNotesUrl} // this to be reverted once VENAU-8090 is fixed
                            /> 
                    </div>
                }
                {
                  appReleasesInfo?.iosAppRelease && appReleasesInfo.windowsAppReleases.length > 0 && 
                    <div className="col" style={{maxWidth: '32%'}}>
                        <AppReleases
                            title={`${t('AuraFor')} ${OSType.IOS}`}
                            osType={OSType.IOS}
                            appSetupUrl={isKolClinic ? kolAppSetUpUrl.iOS :iOSAppSetupUrl}
                            releaseNotesUrl={appReleasesInfo.windowsAppReleases[0].releaseNotesUrl} // this to be reverted once VENAU-8090 is fixed
                            version={appReleasesInfo.windowsAppReleases[0].version} // this to be reverted once VENAU-8090 is fixed
                            date={appReleasesInfo.windowsAppReleases[0].date} // this to be reverted once VENAU-8090 is fixed
                            image={iosIcon} />
                    </div>
                }
            </div>
            {appReleasesInfo?.windowsAppReleases && appReleasesInfo.windowsAppReleases.length > 0 &&
                <div className="row mt-5">
                    <Accordion header={t("ReleaseHistory")}
                        children={
                            <WindowsAppReleaseHistory windowsAppReleases={appReleasesInfo.windowsAppReleases.slice(1)} />
                        } />
                </div>
            }
        </>
    )
}
export default App