import { useEffect, useMemo } from "react";
import { v1 } from "src/Utils/ApiVersions";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useGetRequest } from "../ApiWrappers/useGetRequest";
import { IClinicUser } from "./useGetUserDetails";
import { useGetClinicInfo } from "./useGetClinicInfo";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useGetCurrentUserInfo = () => {
    const { clinicDetails, clinicError, clinicLoading, getClinicInfo, statusCode} = useGetClinicInfo()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
    const [getDetails, currentUser, loading, error] = useGetRequest<IClinicUser>();
    useEffect(()=> {
      getClinicInfo();
    }, [getClinicInfo])
    useEffect(() => {
      if(clinicDetails){
         const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicDetails.clinicId}/user/me`;
         logger.logInformation(`A request has been submitted to fetch user details for url:  ${requestUrl}`)
         getDetails(scope, requestUrl)
      }
    },[getDetails, clinicDetails, scope, baseUrl])
      return { 
        currentUser,
        loading,
        error,
        clinicError,
        clinicLoading,
        statusCode
     };
  }