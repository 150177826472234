import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { usePostRequest } from "../ApiWrappers/usePostRequest";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";

export enum IClinicApiOperations {
    InviteUser,
    MakeAdmin,
    RemoveAdmin
}

export interface IInviteUserRequest {
    email:string,
    isAdmin?:boolean
}
export const useClinicService = () => {

    const {postRequest, reqestError, reqestLoading, reqestResponse} = usePostRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);

    const addClinicUser = useCallback((req:IInviteUserRequest, clinicId:string) => {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicId}/user`;
        logger.logInformation(`A request has been submitted to invite the user to the clinic. EmailId is ${req.email}, clinicId is ${clinicId}.`)
        postRequest<IInviteUserRequest>(req,requestUrl)
    },[baseUrl, postRequest]) 

    const reInviteClinicUser = useCallback((req:string, clinicId:string) => {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicId}/user/re-invite`;
        logger.logInformation(`A request has been submitted to re-invite the user to the clinic. EmailId is ${req}, clinicId is ${clinicId}.`)
        postRequest<string>(req,requestUrl)
    },[baseUrl, postRequest]) 

    const addClinicLocation = useCallback((location:string, clinicId:string) => {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicId}/assign-location`;
        postRequest<string>(location,requestUrl)
    },[baseUrl, postRequest]) 

    const removeClinicLocation = useCallback((location:string, clinicId:string) => {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicId}/remove-location`;
        postRequest<string>(location,requestUrl)
    },[baseUrl, postRequest]) 


    return { addClinicUser, addClinicLocation, removeClinicLocation, reInviteClinicUser, reqestResponse, reqestLoading, reqestError };
}