import { Configuration, InteractionRequiredAuthError, PublicClientApplication } from "@azure/msal-browser";
import { Config } from "./Utils/ConfigService/LoadConfig";
import { logger } from "./Utils/LoggerService/LoggerService";
import i18next from "i18next";

export class AuthConfig {
  private static instance: PublicClientApplication;

  public static getMsalInstance = (): PublicClientApplication => {
    let config = Config.GetConfigData();
    if (!AuthConfig.instance) {
      const msalConfig: Configuration = {
        auth: {
          clientId: config.authConfig.clientId,
          authority: config.authConfig.authorities.signUpSignIn.authority,
          redirectUri: config.authConfig.redirectUri,
          knownAuthorities: [config.authConfig.authorityDomain],
          navigateToLoginRequestUrl: true,
        },
        cache: {
          cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
          storeAuthStateInCookie: false
        },
        system: {
          tokenRenewalOffsetSeconds: 300
        }
      };
      AuthConfig.instance = new PublicClientApplication(msalConfig);
    }
    return AuthConfig.instance;
  }


  public static signOut = () => {
    AuthConfig.getMsalInstance().logoutRedirect();
  }

  public static signIn = () => {
    AuthConfig.getMsalInstance().loginRedirect();
  }

  public static getToken = (scopId: string): Promise<string> => {
    const requestObj = {
      scopes: [scopId]
    };
    let msg = i18next.t('YourSessionExpired');
    const authResponse = new Promise<string>((resolve, reject) => {
      logger.logInformation('Requesting access token for scope: ' + scopId);
      AuthConfig.getMsalInstance().acquireTokenSilent(requestObj)
        .then(
          (response) => {
            resolve(response.accessToken)
          },
          (error) => {
            logger.logError(JSON.stringify(error))
            if (error instanceof InteractionRequiredAuthError) {
               // fallback to interaction when silent call fails
               logger.logInformation('Re generating access token ' +scopId)
              return AuthConfig.getMsalInstance().acquireTokenRedirect(requestObj);
            } else {
              logger.logException(JSON.stringify(error));
              reject(msg);
            }
          }
        )
        .catch(
          (error) => {
            logger.logException(JSON.stringify(error));
            reject(msg);
          }
        );
    });
    return authResponse;
  }
}




