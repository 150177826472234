import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropDown from "src/Components/DropDown/DropDown";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { ClinicRoles } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";
import { IClinicUser } from "src/Utils/Hooks/GetClinicInfo/useGetUserDetails";
import { AdminRoleOPeration, useGetManageUserRoles } from "src/Utils/Hooks/ManageUserRoles/ManageUserRoles";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";

export interface IUserRoles {
    user: IClinicUser;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
};
const UpdateUserRole: React.FC<IUserRoles> = ({ user, setInfo, updatePageLoader }) => {
    const { updateRoles, manageRolesStatus, manageRolesError, manageRolesLoading } = useGetManageUserRoles()
    const { t } = useTranslation();
    const {currentUser} = useClinicContext()
    const [selectedUserId, setSelectedUserId] = useState('')
    const defaultRole = "Member";
    const adminRole = "Admin";
    const userRoles: string[] = [defaultRole, adminRole];

    const onRoleChange = useCallback((user: IClinicUser, item: string) => {
        setSelectedUserId(user.firstName)
        updateRoles({
            clinicId: user.clinicId,
            email: user.email,
            roleOperation: item === defaultRole ? AdminRoleOPeration.Remove : AdminRoleOPeration.Add
        }
        )
    }, [updateRoles])

    const isAdmin = (roles: string[]) => {
        return roles.indexOf(ClinicRoles.ClinicAdmin) >= 0 ? true : false;
    }

    useEffect(() => {
        if (manageRolesStatus) {
            let successMessage = t('RoleUpdateSuccessMsg', {selectedUserId: selectedUserId} )
            logger.logInformation(successMessage);
            setInfo(successMessage, InfoMessageType.success);
        }
        if (manageRolesError !== '') {
            logger.logError(`Failed to update ${selectedUserId} Role. Error occured: ${manageRolesError}`)
            setInfo(manageRolesError, InfoMessageType.error)
        }
    }, [manageRolesStatus, manageRolesError, selectedUserId, setInfo, t])
    
    useEffect(()=> {
        updatePageLoader(manageRolesLoading);
    },[manageRolesLoading, updatePageLoader])

    return (
        <div style={{display: 'flex'}}>
             <DropDown
             disabled={!currentUser?.isClinicAdmin}
             items={userRoles}
             selectedItem={isAdmin(user.roles) ? adminRole : defaultRole}
             onSelectionChange={(item) => onRoleChange(user, item)}
             />
        </div>
    );
};

export default UpdateUserRole;