import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import { useCallback, useEffect, useState } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import axios from 'axios';
import { useClinicContext } from '../ClinicContext';
export interface IClinicLicenseInfo {
  entitlementId: string,
  licenseType: string,
  maximumSeats: number,
  availableSeats: number,
  licenseEndDate: string
}
export interface IClinicLicenseUserInfo {
  entitlementId: string,
  userEmail: string,
  loginTime: string,
  appDeviceInfo: string,
  sessionId: string
}
export interface ILicensesInfo {
  clinicId: string,
  licenceInfo: IClinicLicenseInfo[],
  clinicLicenceUserInfo: IClinicLicenseUserInfo[]
}

export enum LicenseType {
  AuraAppPermanent = 'Aura App Permanent',
  AuraAppDemo = "Aura App Demo",
  AuraAppSubscription = 'Aura App Subscription',
  AuraAppTrial = 'Aura App Trial',
  AuraCloudOneyrSubscription = "Aura Cloud 1yr Subscription"
}

export interface ILicenseStats {
  total: number,
  inUse: number
}

export const useGetLicensesInfo = () => {
  const [licensesInfo, setLicensesInfo] = useState<ILicensesInfo>()
  const [licensesInfoLoading, setlicensesInfoLoading] = useState(false)
  const [licenseInfoError, setLicenseInfoError] = useState('')
  const {clinicInfo } = useClinicContext();
  
  const getLicencesInfo = useCallback((clinicId: string) => {
    const scope = Config.GetConfigData().auraLicensingService?.scopes[0];
    const baseUrl = Config.GetConfigData().auraLicensingService.serviceUrl;
    const requestUrl = `${baseUrl}api/v1/license/clinic/${clinicId}`;
    setlicensesInfoLoading(true);
    setLicenseInfoError('');
    AuthConfig.getToken(scope).then(
      (success) => {
        const headers = {
          Authorization: `Bearer ${success}`,
        };
        logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
        axios
          .get(requestUrl, { headers })
          .then(
            (response) => {
              setLicensesInfo(response.data);
              const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
              logger.logInformation(`Get request to ${requestUrl} for license details was successful and ${status}.`);
              setLicenseInfoError('');
            },
            (error) => {
              const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
              logger.logError(`Error occured to fetch license details for ${requestUrl}: ${status}.`)
              if (error.message && error.message !== '') {
                setLicenseInfoError(error.message);
              } else {
                setLicenseInfoError(error.response.data);
              }
              setLicensesInfo(undefined);
            }
          )
          .finally(() => {
            setlicensesInfoLoading(false);
          });
      },
      (error) => {
        setlicensesInfoLoading(false);
        setLicenseInfoError(error);
      }
    );
}, [])

  useEffect(() => {
    if(clinicInfo){
      getLicencesInfo(clinicInfo.clinicId)
    }
  }, [clinicInfo, getLicencesInfo])


  return { licensesInfo, licenseInfoError, licensesInfoLoading, getLicencesInfo };
};
