import { useEffect } from "react";
import { useTranslation } from "react-i18next"
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { IReleaseLicenceRequest, useReleaseLicence } from "src/Utils/Hooks/ManageLicenses/useReleaseLicence";
import { logger } from "src/Utils/LoggerService/LoggerService";

interface IReleaseLicenceParams {
    clinicId: string;
    sessionId: string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
}
const ReleaseLicence: React.FC<IReleaseLicenceParams> = ({ clinicId, sessionId, setInfo, updatePageLoader }) => {
    const { t } = useTranslation();
    const { requestResponse, requestError, requestLoading, releaseLicence } = useReleaseLicence();
    const req: IReleaseLicenceRequest = {
        clinicId: clinicId,
        sessionId: sessionId
    }
    useEffect(() => {
        if (requestResponse) {
            setInfo("User licence has been released successfully", InfoMessageType.success);
            logger.logInformation(`User licence has been successfully released. Clinic Id: ${clinicId}, Session Id: ${sessionId}`)
        }
    }, [requestResponse, clinicId, sessionId, setInfo])

    useEffect(() => {
        if (requestError !== '') {
            setInfo("Failed to release the user licence", InfoMessageType.error);
            logger.logInformation(`Failed to release the user licence. Clinic Id: ${clinicId}, Session Id: ${sessionId}`)
        }
    }, [requestError, clinicId, sessionId, setInfo])

    useEffect(() => {
        updatePageLoader(requestLoading)
    }, [updatePageLoader, requestLoading])

    return (
        <div className="dropdown-item" onClick={() => { releaseLicence(req) }} id="release-licence">
            {t('ReleaseLicence')}
        </div>
    )
}
export default ReleaseLicence