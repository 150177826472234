import './NavbarContentWrapper.css'
const NavbarContentWrapper:React.FC<{children:React.ReactNode}> = ({children}) => {
    return (   
        <div className="content-wrapper">
            <div className="row content-layout mt-4">
                   {children}
            </div>
        </div>
    )
}
export default NavbarContentWrapper;