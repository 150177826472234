import { useCallback, useEffect, useState } from 'react';
import { IClinicUser, useGetUserInfo } from 'src/Utils/Hooks/GetClinicInfo/useGetUserDetails';
import tableStyles from 'src/Utils/CssModules/table.module.css';
import InfoBar, { IInfobarContent, InfoMessageType } from '../InfoBar/InfoBar';
import Loader from "../Loader/Loader";
import './Users.css'
import UpdateUserRoles from './UserRoles/UpdateUserRoles';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import InviteUser from './InviteUser/InviteUser';
import UserManagement from './UserManagement/UserManagement';
import ProfileIcon from '../ProfileIcon/ProfileIcon';
import { useTranslation } from 'react-i18next';
import { useInfobarTimeout } from 'src/Utils/Hooks/InfobarTimeout/useInfobarTimeout';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';

const Users = () => {
  const { getUserDetails, userDetails, userError, userLoading } = useGetUserInfo();
  const {currentUser} = useClinicContext()

  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [userList, setUserList] = useState<IClinicUser[]>([]);
  const [reInviteLoader, setReinviteLoader] = useState(false);
  const [infoBar, setInfoBar] = useState<boolean>(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const { t } = useTranslation();

  const [infoBarData, setInfoBarData] = useState<IInfobarContent>({
    Message: '',
    MessageType: InfoMessageType.none
  })
  const { setInfobarTimeout } = useInfobarTimeout();

  const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
    setInfoBar(true);
    let content: IInfobarContent = {
      Message: message,
      MessageType: messageType
    }
    setInfoBarData(content);
    setInfobarTimeout(() => setInfoBar(false));
  }, [setInfobarTimeout])

  const sortUsers = (list: IClinicUser[]) => {
    list.sort((a, b) => {
      // Check for null or undefined firstName and move them to the top
      if (a.firstName === null || a.firstName === undefined) return -1;
      if (b.firstName === null || b.firstName === undefined) return 1;
      return a.firstName.localeCompare(b.firstName);
    })
    return list;
  }
  useEffect(() => {
    if (userError !== '') {
      setInfo(userError, InfoMessageType.error)
    }
  }, [userError, setInfo])

  useEffect(()=> {
    if(userDetails) {
      setUserList(sortUsers(userDetails))
    }
  },[userDetails])

  const updateUsers = useCallback((message: string, messageType: InfoMessageType) => {
    setInfo(message, messageType);
    if (messageType === InfoMessageType.success) {
      getUserDetails()
    }
  }, [getUserDetails, setInfo])
  return (
    <>
      {pageLoader && <Loader />}
      {reInviteLoader && <Loader />}
      {userLoading && <Loader />}
      {showConfirmDialog && userDetails &&
        <InviteUser
          onClose={() => setShowConfirmDialog(false)}
          updatePageLoader={setPageLoader}
          setInfo={(message, type) => {
            setInfo(message, type);
            getUserDetails()
          }}
          clinicId={userDetails[0].clinicId}
        />
      }
      {infoBar && (
        <div className="row" style={{ paddingLeft: '1vw' }}>
          <InfoBar
            Message={infoBarData.Message}
            MessageType={infoBarData.MessageType}
            closeClick={() => {
              setInfoBar(false);
            }}
          />
        </div>
      )}
      <div className="row mt-3 d-flex justify-content-end">
          <ButtonWithIcon
            backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
            onClick={() => { setShowConfirmDialog(true) }}
            label={t('NewUser')}
            icon='bi bi-plus-lg'
            testId='newUser'
            color='white'
            disable={!currentUser?.isClinicAdmin}
          />
      </div>
      <div className='row mt-5'>
        <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable}`}>
          {userDetails &&
            <table style={{ width: '100%' }} data-testid="table" className={`table table-striped`}>
              <thead className={`${tableStyles.tableHead}`}>
                <tr key={'header'}>
                  <th></th>
                  <th className={tableStyles.heading}>{t('FirstName')}</th>
                  <th className={tableStyles.heading}>{t('LastName')}</th>
                  <th className={tableStyles.heading}>{t('Email')}</th>
                  <th className={tableStyles.heading}>{t('Role')}</th>
                  <th className={tableStyles.heading}></th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user) => (
                  <tr key={user.email}>
                    <td>
                      <ProfileIcon
                        firstName={user.firstName}
                        lastName={user.lastName}
                      />
                    </td>
                    <td className={tableStyles.data} style={{fontWeight: 600}}>
                      {user.firstName? user.firstName : ''}
                    </td>
                    <td className={tableStyles.data} style={{fontWeight: 600}}>
                      {user.lastName? user.lastName : ''}
                    </td>
                    <td className={tableStyles.data}>{user.email}</td>
                    <td className={tableStyles.data} > 
                        <UpdateUserRoles
                          user={user}
                          setInfo={updateUsers}
                          updatePageLoader={setPageLoader}
                        />      
                    </td>
                    <td className={tableStyles.data}>                       
                              <UserManagement
                                user={user}
                                setInfo={setInfo}
                                updateUsers={getUserDetails}
                                setLoaderState={setReinviteLoader}
                              />                          
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </div>
      </div>
    </>
  )
}
export default Users;