import React, { useRef, useState } from "react";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import MoreOptionsMenu from "src/Components/MoreOptions/MoreOptions";
import { UserStatus, IClinicUser } from "src/Utils/Hooks/GetClinicInfo/useGetUserDetails";
import DeleteUser from "../DeleteUser/DeleteUser";
import ReinviteUser from "../ReinviteUser/ReinviteUser";
import { useTranslation } from "react-i18next";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
interface IUserManagement {
    user: IClinicUser;
    updateUsers: () => void;
    setInfo: (message: string, type: InfoMessageType) => void;
    setLoaderState: (load: boolean) => void;
}

const UserManagement: React.FC<IUserManagement> = ({ user, setInfo, updateUsers, setLoaderState }) => {
    const [showDeleteUserDialog, setshowDeleteUserDialog] = useState<boolean>(false);
    const userName = useRef<string>(user.lifecycle.status === UserStatus.Created ? `${user.firstName} ${user.lastName}` : user.email);
    const { t } = useTranslation();
    const {currentUser} = useClinicContext()
    return (
        <>
            {
                showDeleteUserDialog &&
                <DeleteUser
                    userId={user.userId}
                    clinicId={user.clinicId}
                    userName={userName.current}
                    setInfo={setInfo}
                    setLoaderState={setLoaderState}
                    updateUsers={updateUsers}
                    onClose={() => setshowDeleteUserDialog(false)} />
            }
            <div className="px-2">
                <MoreOptionsMenu id='user-management' disabled={!currentUser?.isClinicAdmin}>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {(user.lifecycle.status !== UserStatus.Created) &&
                            <li key="1"><ReinviteUser email={user.email} clinicId={user.clinicId} setInfo={setInfo} setLoaderState={setLoaderState} /></li>}
                            <li key="2"> <div id="delete-user" className="dropdown-item" onClick={() => setshowDeleteUserDialog(true)} style={{color: "#FF0F00"}}>{t('DeleteUser')}</div></li>
                    </ul>
                </MoreOptionsMenu>
            </div>
        </>

    );
};

export default UserManagement;
