import { useEffect, useRef } from "react"
import { InfoMessageType } from "src/Components/InfoBar/InfoBar"
import React from "react";
import { useDeviceRename } from "src/Utils/Hooks/ManageDevices/useDeviceRename";
import InputBoxWithButton from "src/Components/InputBoxWithButton/InputBoxWithButton";
import useOutsideClick from "src/Utils/Hooks/UseOutSideClick/UseOutSideClick";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";

interface IDeviceName {
    serialNumber:string,
    deviceId: string,
    deviceName: string,
    showRenameBox: boolean,
    clinicId: string,
    setInfo: (message: string, type: InfoMessageType) => void;
    setLoaderState: (load: boolean) => void;
    closeRenameBox: () => void;
}

const DeviceName: React.FC<IDeviceName> = ({ deviceId, serialNumber, deviceName, showRenameBox, clinicId, setInfo, setLoaderState, closeRenameBox }) => {
    const { deviceRenameError, deviceRenameLoading, deviceRenameResponse, renameDevice } = useDeviceRename();
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    useOutsideClick(wrapperRef, () => { closeRenameBox() })
    const reName = useRef('');

    useEffect(() => {
        if (deviceRenameResponse) {
            let successMsg = t('RenameDeviceInstruction', {serialNumber: serialNumber, name: reName.current})
            logger.logInformation(successMsg + `Device Id is : ${deviceId}.`)
            setInfo(successMsg, InfoMessageType.success);
        }
    }, [deviceRenameResponse, serialNumber, deviceId, setInfo, reName, t])

    useEffect(() => {
        if (deviceRenameError !== '') {
            logger.logError(`Failed to rename the device ${serialNumber} with device Id ${deviceId} to ${reName.current}: ${deviceRenameError}`)
            setInfo(deviceRenameError, InfoMessageType.error);
        }
    }, [deviceRenameError, reName, serialNumber, deviceId, setInfo])

    useEffect(() => {
        setLoaderState(deviceRenameLoading);
    }, [deviceRenameLoading, setLoaderState])

    return (
        <div ref={wrapperRef}>
            {
                showRenameBox ?
                    (<InputBoxWithButton
                        placeholder={deviceName}
                        testId="device-name"
                        submitText='OK'
                        onSubmit={(name: string) => {
                            reName.current = name;
                            renameDevice(deviceId, clinicId, name);
                            closeRenameBox();
                        }}
                    />) :
                    (<div style={{fontSize: '20px', fontWeight: '600'}}>
                        {deviceName}
                    </div>)
            }
        </div>
    )
}

export default React.memo(DeviceName);