import { useEffect } from 'react';

/**
 *
 * @param {*} ref - Ref of your parent div
 * @param {*} callback - Callback which can be used to change your maintained state in your component
 */

const useOutsideClick = (ref: React.RefObject<HTMLDivElement|null>, callback: () => void ) => {
    useEffect(() => {
        const checkIfClickedOutside = (e: { target: any; }) => {
          if (ref.current && !ref.current.contains(e.target)) {
            callback();
          }
        };
    
        document.addEventListener("click", checkIfClickedOutside, true);
        return () => {
          document.removeEventListener("click", checkIfClickedOutside, true);
        };
      },);
};

export default useOutsideClick;