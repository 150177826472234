import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { useClinicService } from "src/Utils/Hooks/GetClinicInfo/useClinicServic";
import { logger } from "src/Utils/LoggerService/LoggerService";

interface IReinviteUserProps {
    email: string;
    clinicId: string;
    setLoaderState: (load: boolean) => void;
    setInfo: (message: string, type: InfoMessageType) => void;
}

const ReinviteUser: React.FC<IReinviteUserProps> = ({ email, clinicId, setInfo, setLoaderState }) => {
    const { reInviteClinicUser, reqestResponse, reqestError, reqestLoading } = useClinicService();
    const { t } = useTranslation();

    useEffect(() => {
        if (reqestResponse) {
            logger.logInformation(`User is reinvited successfully. EmailId is ${email}, clinicId is ${clinicId}.`);
            setInfo(t('UserReinviteSuccessMsg', { email }), InfoMessageType.success);
        } else if (reqestError) {
            logger.logError(`Error occur while reinviting user to clinic. EmailId is ${email}, clinicId is ${clinicId}.`);
            setInfo(reqestError, InfoMessageType.error);
        }
    }, [reqestResponse, reqestError, email, clinicId, setInfo, t]);

    useEffect(() => {
        setLoaderState(reqestLoading);
    }, [reqestLoading, setLoaderState])

    const handleReinviteClick = () => {
        reInviteClinicUser(email, clinicId);
    };

    return (
        <div className="dropdown-item" id="reinvite-user" onClick={handleReinviteClick}>
            {t('ReinviteUser')}
        </div>
    );
};

export default ReinviteUser;
