import React, { useState } from 'react';
import './InputBoxWithButton.css'
import { useTranslation } from 'react-i18next';

export interface IButtonParams {
    placeholder: string;
    testId: string;
    submitText: string;
    onSubmit: (value: string) => void;
}

const InputBoxWithButton: React.FC<IButtonParams> = ({
    placeholder,
    testId,
    submitText,
    onSubmit
}) => {
    const [inputValue, setInputValue] = useState<string>(placeholder);
    const { t } = useTranslation();
    return (
        <>
            <div className="input-group input-container">
                <input type="text"
                    className="form-control input-field"
                    value={inputValue}
                    id='input-value'
                    onChange={(e) => setInputValue(e.target.value)}
                />
                <div className="input-group-append">
                    <button className="submit-button"
                        data-testid={testId}
                        onClick={() => {
                            onSubmit(inputValue)
                        }}
                        id={submitText}
                        disabled={inputValue === ''}>
                        {t(submitText)}
                    </button>
                </div>
            </div>
        </>
    );
};
export default InputBoxWithButton;