import { Config } from '../../ConfigService/LoadConfig';
import { useCallback } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { usePostRequest } from '../ApiWrappers/usePostRequest';
import { ILicenceRequest } from './useActivateLicence';


export const useRefreshLicence = () => {
  const {postRequest, reqestResponse: refreshLicensesInfo, reqestError: refreshLicenseInfoError, reqestLoading: refreshLicensesInfoLoading} = usePostRequest();
  const refreshLicence = useCallback((req: ILicenceRequest, clinicId: string) => {
      const baseUrl = Config.GetConfigData().auraLicensingService.serviceUrl;
      const requestUrl = `${baseUrl}api/v1/license/clinic/${clinicId}/refresh`;
      logger.logInformation(` request to ${requestUrl} to refresh license details was made, request: ${JSON.stringify(req)}`);
      postRequest<ILicenceRequest>(req, requestUrl);
      }, [postRequest])

  return { refreshLicensesInfo, refreshLicenseInfoError, refreshLicensesInfoLoading, refreshLicence };
};
