import ButtonWithIcon from 'src/Components/ButtonWithIcon/ButtonWithIcon'
import './NoLicenseActivated.css'
import { useState } from 'react';
import AddNewLicence from '../AddNewLicence/AddNewLicence';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { useTranslation } from 'react-i18next';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';
interface INoLicenseActivatedParams{
    clinicId: string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
    updateList: () => void;
}
const NoLicenseActivated: React.FC<INoLicenseActivatedParams> = ({clinicId, setInfo, updatePageLoader, updateList}) => {
    const [showAddNewLicence, setShowAddNewLicence] = useState<boolean>(false);
    const {currentUser} = useClinicContext()
    const { t } = useTranslation();
    return (
        <>
        {
            showAddNewLicence && 
            <AddNewLicence 
            clinicId={clinicId} 
            setInfo={setInfo}
            updatePageLoader={updatePageLoader}
            updateList={updateList}
            onClose={() => {setShowAddNewLicence(false);}}/>
        }
            <div className='noLicenseContainer'>
                <div className='title'>{t('NoLicenceActivated')}</div>
                <div className='text mt-3'>{t('ActivationReminderMessage')}</div>
                <div className='mt-4 d-flex justify-content-center'>
                    <ButtonWithIcon
                        backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                        onClick={() => { setShowAddNewLicence(true) }}
                        label={t('AddLicence')}
                        icon='bi bi-plus-lg'
                        testId='newUser'
                        color='white'
                        disable={!currentUser?.isClinicAdmin}
                    />
                </div>
                <div className='text mt-3'>{t('DownloadAppLocationInstruction')}</div>
            </div>
        </>
    )
}
export default NoLicenseActivated