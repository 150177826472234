import {useMsal } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';


import './AppLayout.css';
import React, { useEffect } from 'react';
import { EventType } from '@azure/msal-browser';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import Device from '../Device/Device';
import WelcomePage from '../WelcomePage/WelcomePage';
import MainLayout from './MainLayout';
import ClinicProfile from '../ClinicProfile/ClinicProfile';
import Downloads from '../Downloads/Downloads';
import LicensesAndStorage from '../LicensesAndStorage/LicensesAndStorage';
import App from '../App/App';
import Users from '../Users/Users';
import NoAccess from '../NoAccess/NoAccess';


const AppLayout = () => {

     /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
     const { instance } = useMsal();
     const authConfig = Config.GetConfigData().authConfig;

     useEffect(() => {
         const callbackId = instance.addEventCallback((event:any) => {
             if (
                 (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                 event.payload.account
             ) {
                 /**
                  * For the purpose of setting an active account for UI update, we want to consider only the auth
                  * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                  * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                  * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                  */
                 if (event.payload.idTokenClaims['tfp'] === authConfig.names.editProfile) {
                     // retrieve the account from initial sing-in to the app
                     const originalSignInAccount = instance
                         .getAllAccounts()
                         .find(
                             (account) => account.idTokenClaims &&
                                 account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                 account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                 account.idTokenClaims['tfp'] === authConfig.names.signUpSignIn
                         );
 
                     let signUpSignInFlowRequest = {
                         authority: authConfig.authorities.signUpSignIn.authority,
                         account: originalSignInAccount,
                     };
 
                     // silently login again with the signUpSignIn policy
                     instance.ssoSilent(signUpSignInFlowRequest);
                 }
             }
         });
 
         return () => {
             if (callbackId) {
                 instance.removeEventCallback(callbackId);
             }
         };
         // eslint-disable-next-line
     }, [instance]);
     
  return (
    <>
      <Router>
          <div className="main-container">
            <div className="main-content">      
                <Routes>
                    <Route path="" element={<MainLayout />}>
                        <Route path="/users" element={<Users />} />
                        <Route path="/device" element={<Device/>} />
                        <Route path="/licences" element={<LicensesAndStorage/>} />
                        <Route path="/clinicprofile" element={<ClinicProfile/>} />
                        <Route path="/downloads" element={<Downloads/>} />
                        <Route path="/" element={<Navigate to="/clinicprofile" />} />
                        <Route path="/app" element={<App/>} />
                    </Route>
                    <Route path="/noaccess" element={<NoAccess/>} />
                    <Route path="/welcome" element={<WelcomePage/>} />
                </Routes>
            </div>
            
          </div>
      </Router>
    </>
  );
};
export default React.memo(AppLayout);
