
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import { v1 } from 'src/Utils/ApiVersions';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { useClinicContext } from '../ClinicContext';
export enum  UserStatus {
  NotYetInvited='NotYetInvited',
  Invited='Invited',
  Created='Created'
}
export interface lifeCycle {
  invitedOn:Date,
  status: UserStatus
}
export interface IClinicUser {
   firstName:string,
   lastName:string,
   email:string,
   roles:string[],
   userId: string,
   clinicId:string,
   lifecycle:lifeCycle
}

export interface IClinicUserInfo {
  clinicUser: IClinicUser,
  isClinicAdmin: boolean
}
export var ClinicAdmin = 'clinic-admin';
export var DefaultClinicUser = 'clinic-user';

export const useGetUserInfo = () => {
  const [userDetails, setUserDetails] = useState<IClinicUser[]>();
  const [userError, setUserError] = useState<string>('');
  const [userLoading, setUserLoading] = useState<boolean>(false);
  const {clinicInfo } = useClinicContext();

  const getUserDetails = useCallback(() => {
    setUserLoading(true);
    setUserError('');
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
    const baseUrl = Config.GetConfigData().serviceUrl;

    const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicInfo?.clinicId}/user`;
    AuthConfig.getToken(scope).then(
        (success) => {
          const headers = {
            Authorization: `Bearer ${success}`,
          };
          logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
          axios
            .get(requestUrl, { headers })
            .then(
              (response) => {
                setUserDetails(response.data);
                const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                logger.logInformation(`Get request to ${requestUrl} for user details was successful and ${status}.`);
                setUserError('');
              },
              (error) => {
                const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                logger.logError(`Error occured to fetch user details for ${requestUrl}: ${status}.`)
                if(error.message !== '') {
                  setUserError(error.message);
                } else {
                  setUserError(error.response.data);
                }
                setUserDetails(undefined);
              }
            )
            .finally(() => {
                setUserLoading(false);
            });
        },
        (error) => {
          setUserLoading(false);
          setUserError(error);
        }
      );
  },[clinicInfo?.clinicId])

  useEffect(() => {
    if(clinicInfo?.clinicId) {
        getUserDetails()
    }
  },[getUserDetails, clinicInfo?.clinicId])

  return {getUserDetails,  userDetails, userError, userLoading };
};
