

import {useCallback, useEffect, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import axios from 'axios';
import { v1 } from 'src/Utils/ApiVersions';
import { logger } from 'src/Utils/LoggerService/LoggerService';
export interface ILatestFirmware {
 version:string;
 fileName:string
}
export const useGetFirmwareVersion = () => {
    const [firmwareVersionDetails, setFirmwareVersionDetails] = useState<ILatestFirmware>();
    const [firmwareVersionError, setFirmwareVersionError] = useState<string>('');
    const [firmwareVersionLoading, setFirmwareVersionLoading] = useState<boolean>(false);
    
    // find latest version - https://stackoverflow.com/questions/6832596/how-can-i-compare-software-version-number-using-javascript-only-numbers
    // console.log(cmpVersions('1.0', '0.0.5'))  // return 1
    // console.log(cmpVersions('0.0.5', '1.0'))  // return -1
    // console.log(cmpVersions('1.0', '1.0'))  // return -1
    const cmpVersions = useCallback((a:string, b:string) => {
        var i, diff;
        var regExStrip0 = /(\.0+)+$/;
        var segmentsA = a.replace(regExStrip0, '').split('.');
        var segmentsB = b.replace(regExStrip0, '').split('.');
        var l = Math.min(segmentsA.length, segmentsB.length);
    
        for (i = 0; i < l; i++) {
            diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
            if (diff) {
                return diff;
            }
        }
        return segmentsA.length - segmentsB.length;
    },[])


  const getFirmwareVersion = useCallback(() => {
      setFirmwareVersionLoading(true);
      setFirmwareVersionError('');
      const scope = Config.GetConfigData().auraWebService?.scopes[0];
      const baseUrl = Config.GetConfigData().serviceUrl;
      const requestUrl = `${baseUrl}api/${v1}/firmware`;

      AuthConfig.getToken(scope).then(
          (success) => {
            const headers = {
              Authorization: `Bearer ${success}`,
            };
            axios
              .get(requestUrl, { headers })
              .then(
                (response) => {
                    var versions:ILatestFirmware[] = response.data;
                    var allVersions = versions.map((fwVersion) => {return fwVersion.version});
                    var sortedVersions = allVersions.sort(cmpVersions)
                    var latestVersions = versions.filter((fwVersons) => fwVersons.version === sortedVersions[sortedVersions.length -1])
                    setFirmwareVersionDetails(latestVersions[0]);
                    setFirmwareVersionError('');
                    const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                    logger.logInformation(`Get request to ${requestUrl} for firmware details was successful and ${status}.`);
                },
  
                (error) => {
                  const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                  logger.logError(`Error occured to fetch firmware details for ${requestUrl}: ${status}.`)
                  if(error.message && error.message !== '') {
                    setFirmwareVersionError(error.message);
                  } else {
                    setFirmwareVersionError(error.response.data);
                  }
                  setFirmwareVersionDetails(undefined);
                }
              )
              .finally(() => {
                setFirmwareVersionLoading(false);
              });
          },
          (error) => {
            setFirmwareVersionLoading(false);
            setFirmwareVersionError(error);
          }
        );
      }
  , [cmpVersions]);

   useEffect(() => {
    getFirmwareVersion()
  }, [getFirmwareVersion])
  return { getFirmwareVersion, cmpVersions, firmwareVersionDetails, firmwareVersionError, firmwareVersionLoading };
};
