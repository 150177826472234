import { useEffect } from "react"
import { InfoMessageType } from "src/Components/InfoBar/InfoBar"
import SmallDialog from "src/Components/Dialog/SmallDialog";
import React from "react";
import { useCancelFirmwareUpdate } from "src/Utils/Hooks/ManageDevices/useCancelFirmwareUpdate";
import CancelFwUpdateInstructions from "./CancelFwUpdateInstructions";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";
interface ICancelFwUpdate {
    serialNumber:string,
    deviceId: string,
    clinicId:string;
    latestFwVersion?:string;
    setInfo: (message: string, type: InfoMessageType) => void;
    setLoaderState: (load: boolean) => void;
    closeDialog: () => void;
}


const CancelFwupdate: React.FC<ICancelFwUpdate> = ({latestFwVersion, deviceId, serialNumber, clinicId, setInfo, setLoaderState, closeDialog}) => {
    const { cancelDeviceFirmwareupdate, cancelFwError, cancelFwLoading, cancelFwResponse } = useCancelFirmwareUpdate();
    const { t } = useTranslation();
    useEffect(() => {
        if (cancelFwResponse) {
            closeDialog();
            let successMsg = t(`CancelUpdateSuccessMsg`, {serialNumber: serialNumber, latestFwVersion: latestFwVersion});
            logger.logInformation(successMsg + `Device Id is ${deviceId} and Clinic Id is ${clinicId}`);
            setInfo(successMsg, InfoMessageType.success);
        }
        else {
            if (cancelFwError) {
                closeDialog()
                logger.logError(`Failed to cancel the firmware update for device ${serialNumber} as it is aleady installing where device Id is ${deviceId} and clinic Id is ${clinicId}`)
                setInfo(t('CancelUpdateErrorMsg'), InfoMessageType.error);
            }
        }

    }, [cancelFwResponse, cancelFwError, serialNumber, setInfo, clinicId, closeDialog, deviceId, latestFwVersion, t])

    useEffect(() => {
        setLoaderState(cancelFwLoading);
    }, [cancelFwLoading, setLoaderState])

    const cancelFwUpdate = () => {
        if(deviceId){
            cancelDeviceFirmwareupdate(deviceId);
        }
    }

    return (
        <>
            
                <SmallDialog
                    submitButtonText="Yes"
                    cancelButtonText="No"
                    title={`Firmware Update ${latestFwVersion}`}
                    onClose={() => { closeDialog() }}
                    onSubmit={() => {
                        cancelFwUpdate()
                    }} >
                    <CancelFwUpdateInstructions  />
                </SmallDialog>
           
        </>
    )
}

export default  React.memo(CancelFwupdate);