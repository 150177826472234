const isValidEmail = (email: string): boolean => {
    const exp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return exp.test(email);
};

const isValidPhoneNumber = (number: string): boolean => {
    const exp: RegExp =
        /^[+\d]{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return exp.test(number);
};

export { isValidEmail, isValidPhoneNumber };
