import { logger } from 'src/Utils/LoggerService/LoggerService';
import './Document.css';
import LineSeparation from 'src/SharedComponents/LineSeparation/LineSeparation';
interface ITechDocGrid {
    title: string;
    image?: string;
    fileLink:string;
    duration?: string;
}
const Document: React.FC<ITechDocGrid> = ({ title, image,fileLink, duration }) => {
    return (
        <div >

            <div className='me-3'>
                <div className='row d-flex mt-4 document' style={{ height: '300px'}}>
                    <div className='m-2'>
                        <div className="m-1">
                            <span className='document-header row'>
                                <div className={`${duration ? 'col-md-10' : 'col-md-12'}`}>
                                    <b>{title}</b>
                                </div>
                                {duration && (
                                <div className='extra-text-right col-2 d-flex justify-content-end'>
                                    {duration}
                                </div>
                               )}
                            </span>
                        </div>

                        <LineSeparation />
                        <div className="m-3">
                            <div className='m-1'>
                                <div style={{ display: 'flex', justifyContent: 'center', placeItems: 'center', position: 'relative', height: '180px', borderRadius: '25px' }} 
                                 onClick={() => {logger.logInformation(`User downloaded file with name: "${title}".`)}}>
                                    <p>
                                        <a
                                            href={fileLink}
                                            target="_blank"
                                            rel="noOpener noReferrer">
                                            <img src={image} alt="Technical document" style={{ cursor: 'pointer' }} /></a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}
export default Document