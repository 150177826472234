import { useTranslation } from "react-i18next";
import InputBox from "../InputBox/InputBox";
import './InputBoxWithLabel.css'

export interface IInputBoxWithLabel {
  label: string;
  width?: string;
  height?: string;
  optional?: boolean;
  value?: string;
  readOnly?: boolean;
  onChange: (text: string) => void;
}
const InputBoxWithLabel: React.FC<IInputBoxWithLabel> = ({ label, width, height, optional, value, readOnly, onChange }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="label mb-2">
        {t(label)}
        {optional && <span className='optional'> ({t('Optional')})</span>}
      </div>
      <InputBox width={width} height={height} value={value} id={label} onChange={onChange} readOnly={readOnly}/>
    </div>
  )
}
export default InputBoxWithLabel;