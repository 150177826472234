import { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { INavbarItem } from '../NavBar';
import './SubNavBar.css';
import { useTranslation } from 'react-i18next';

const SubNavBar: React.FC<{ itemsList: INavbarItem[] }> = (itemsList) => {
  const navItems = useRef(itemsList);
  let itemsLength = navItems.current.itemsList.length;
  let gridWidth = 9*itemsLength;
  let itemWIdth = 100/itemsLength;
  const { t } = useTranslation();

  return (

    <div className="container">
      <div className='d-flex justify-content-center'>
        <div className="row list-grid" style={{width:`${gridWidth}%`}}>
          <div className="nav">
            {
              navItems.current.itemsList.map((item) => (
                <div style={{width:`${itemWIdth}%`}} key={item.id} >
                  <NavLink
                    id={item.id}
                    to={item.routePath}
                    className={'nav-link-item'}
                  >
                    {t(item.name)}             
                  </NavLink>

                </div>
              ))

            }
          </div>
        </div>

      </div>
    </div>
  )
}
export default SubNavBar;