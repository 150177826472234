import { useState } from 'react';
import './InputBox.css'
interface IInputBox {
    width?: string;
    height?: string;
    value?: string;
    readOnly?: boolean;
    id?: string;
    onChange: (text: string) => void;
}
const InputBox: React.FC<IInputBox> = ({ width, height, value, readOnly, id, onChange }) => {
    let initialVal = value ? value : '';
    const [val, setVal] = useState(initialVal);
    return (
        <>
            <div>
                    <input
                        type="text"
                        className="form-control input-box"
                        data-testid="input"
                        style={{width: width, height: height }}
                        value={val}
                        id={id}
                        onChange={(e) => { 
                            setVal(e.target.value); 
                            onChange(e.target.value); 
                        }}
                        readOnly={readOnly ? true : false}
                    />
                </div>
        </>
    )
}
export default InputBox