import { createContext, useContext } from "react";
import { IClinicInfo } from "./GetClinicInfo/useGetClinicInfo";
import { IClinicUserInfo } from "./GetClinicInfo/useGetUserDetails";

export type ClinicContextType = {
    clinicInfo: IClinicInfo | undefined;
    setClinicInfo: (clinicInfo: IClinicInfo) => void;
    currentUser: IClinicUserInfo | undefined
    setCurrentUser: (clinicInfo: IClinicUserInfo) => void;
}

export const ClinicContextDefaultValues: ClinicContextType = {
    clinicInfo: undefined,
    setClinicInfo: () => { },
    currentUser: undefined,
    setCurrentUser: () => { },
}

export const ClinicContext = createContext<ClinicContextType>(ClinicContextDefaultValues);
export const useClinicContext = () => useContext(ClinicContext);
