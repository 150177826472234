import { useTranslation } from "react-i18next";

const UpdateInstallInstructions = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="mt-1">
                <p style={{ textAlign: 'center', marginBottom: '0px', fontSize: '14px' }}>{t('UpdateDeviceInstruction')}</p>
            </div>
        </>
    )
}
export default UpdateInstallInstructions;