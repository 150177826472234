import ButtonWithIcon from '../../ButtonWithIcon/ButtonWithIcon'
import './Licenses.css'
import { ILicenseStats, ILicensesInfo } from 'src/Utils/Hooks/ManageLicenses/useGetLicensesInfo';
import LicenseInfo from '../LicenseInfo/LicenseInfo';
import { useTranslation } from 'react-i18next';
import LicensesInstruction from './LicensesInstruction';
import { useCallback, useEffect, useState } from 'react';
import AddNewLicence from '../AddNewLicence/AddNewLicence';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import NoLicenseActivated from '../NoLicenseActivated/NoLicenseActivated';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';

interface ILicensesParams {
    licensesInfo: ILicensesInfo;
    clinicId: string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
    updateList: () => void;
}
const Licenses: React.FC<ILicensesParams> = ({ licensesInfo, clinicId, setInfo, updatePageLoader, updateList }) => {
    const { t } = useTranslation();
    const [updatedLicenceInfo, setUpdatedLicenceInfo] = useState<ILicensesInfo>(licensesInfo);
    const [licenseStatistics, setLicenseStatistics] = useState<ILicenseStats>({ total: 0, inUse: 0 });
    const {currentUser} = useClinicContext()
    const getDetailsByEntitlementId = useCallback((entitlementId: string) => {
        const filteredDetails = (updatedLicenceInfo.clinicLicenceUserInfo || []).filter(
            (userInfo) => userInfo.entitlementId === entitlementId
        );
        return filteredDetails;
    }, [updatedLicenceInfo]);
    useEffect(()=> {
        var total = 0;
        var inUse = 0;
        updatedLicenceInfo.licenceInfo.forEach(item => {
            total += item.maximumSeats;
            inUse += item.maximumSeats - item.availableSeats;
        });
        setLicenseStatistics({ total, inUse });
    }, [updatedLicenceInfo])
    useEffect(()=> {
        setUpdatedLicenceInfo(licensesInfo)
    }, [licensesInfo])
    const [showAddNewLicence, setShowAddNewLicence] = useState<boolean>(false);

    return (
        <>
            {
                showAddNewLicence &&
                <AddNewLicence
                    clinicId={clinicId}
                    setInfo={setInfo}
                    updatePageLoader={updatePageLoader}
                    onClose={() => { setShowAddNewLicence(false); }}
                    updateList={updateList} />
            }
            {
                (licenseStatistics.total === 0) ?
                    <NoLicenseActivated
                        clinicId={clinicId}
                        setInfo={setInfo}
                        updatePageLoader={updatePageLoader}
                        updateList={updateList} /> :
                    <>
                        <div className="row mt-4">
                            <div className="col">
                                <span className="noOfLicenses">{`${licenseStatistics.inUse}/${licenseStatistics.total}`}</span>
                                <span className='px-2 text'>{licenseStatistics.inUse === 1 ? t('SeatInUse') : t('SeatsInUse')}</span>
                            </div>
                            <div className='col instruction'>
                                {(licenseStatistics.inUse > 0) && (licenseStatistics.inUse === licenseStatistics.total) &&
                                    <LicensesInstruction />
                                }
                            </div>
                            <div className="col d-flex justify-content-end">
                                <ButtonWithIcon
                                    backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                                    onClick={() => { setShowAddNewLicence(true) }}
                                    label={t('AddLicence')}
                                    icon='bi bi-plus-lg'
                                    testId='addLicence'
                                    color='white'
                                    disable={!currentUser?.isClinicAdmin}
                                />
                            </div>
                        </div>
                        <div>
                            {
                                updatedLicenceInfo.licenceInfo.map((item) => {
                                    const detailsForEntitlementId = getDetailsByEntitlementId(item.entitlementId);
                                    return (
                                        <LicenseInfo
                                            licensesList={detailsForEntitlementId}
                                            entitlementId={item.entitlementId}
                                            noOfUnusedLicenses={item.maximumSeats - detailsForEntitlementId.length}
                                            licenseType={item.licenseType}
                                            licenseEndDate={item.licenseEndDate}
                                            clinicId={clinicId}
                                            key={item.entitlementId}
                                            setInfo={setInfo}
                                            updateLicenceInfo={setUpdatedLicenceInfo}
                                            updatePageLoader={updatePageLoader}
                                        />
                                    );
                                })
                            }
                        </div>
                    </>
            }
        </>
    )
}

export default Licenses