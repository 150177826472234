import React from 'react';

interface ExternalLinkButtonProps {
    link: string;
    id: string;
    buttonText: string;
    width: string;
}

const ExternalLinkButton: React.FC<ExternalLinkButtonProps> = ({ link, id, buttonText, width }) => {

    return (
        <a href={link} className="d-flex justify-content-center" style={{ color: "#A8927D", textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" id={id}>
            <button type="button" style={{ width: width }} className='btn about-btn'>
                {buttonText}
            </button>
        </a>
    );
};

export default ExternalLinkButton;
