import LogoText from "src/Components/LogoText/LogoText";
import { IClinicInfo } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import whatsapp from 'src/images/whatsapp.png';
import wechat from 'src/images/wechat.png';
import instagram from 'src/images/instagram.png';
import other from 'src/images/other.png';
import facebook from 'src/images/facebook.png';
import { useMemo } from "react";

interface IClinicSupportInfoParams {
    clinicDetails: IClinicInfo;
}
const ClinicSupportInfo: React.FC<IClinicSupportInfoParams> = ({ clinicDetails }) => {
    const supportChanelTypeLogo: { [key: string]: string } = {
        whatsapp: whatsapp,
        wechat: wechat,
        instagram: instagram,
        facebook: facebook,
        other: other
    };
    const show = useMemo(() => clinicDetails?.supportContactDetail?.contactNumber ||
        clinicDetails?.supportContactDetail?.email ||
        clinicDetails?.supportContactDetail?.supportChannelType ||
        clinicDetails?.supportContactDetail?.supportChannel, [clinicDetails?.supportContactDetail]);
    var logoSrc = supportChanelTypeLogo[clinicDetails.supportContactDetail?.supportChannelType?.toLocaleLowerCase()] || other;
    return (
        <>
            {show &&
                <>
                    <div className="row">
                        <div className="mb-2 col-9">
                            <b>Support Contact</b>
                        </div>
                    </div>
                    {clinicDetails.supportContactDetail?.email && <LogoText logoSrc={mailLogo} text={clinicDetails.supportContactDetail?.email} /> }
                    {clinicDetails.supportContactDetail?.contactNumber && <LogoText logoSrc={phoneLogo} text={clinicDetails.supportContactDetail?.contactNumber} /> }
                    {clinicDetails.supportContactDetail?.supportChannel && <LogoText logoSrc={logoSrc} text={clinicDetails.supportContactDetail?.supportChannel} />}
                </>}
        </>)
}

export default ClinicSupportInfo;