import { useCallback } from "react";
import { usePostRequest } from "../ApiWrappers/usePostRequest";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";

export interface ILicenceRequest{
    entitlementId: string;
    licenceType: number;
}
export enum LicenceType{
    userLicence = 0,
    storageLicence = 0,
}
export const useActivateLicence = () => {
    const {postRequest, reqestResponse, reqestError, reqestLoading} = usePostRequest();
   const baseUrl = Config.GetConfigData().auraLicensingService.serviceUrl;
    const activateNewClinicLicence = useCallback((req: ILicenceRequest, clinicId: string) => {
        const requestUrl = `${baseUrl}api/${v1}/license/clinic/${clinicId}/activate`;
        logger.logInformation(`Request to Activate licence for the given clinic ${clinicId} was made`);
        postRequest<ILicenceRequest>(req, requestUrl);
    }, [baseUrl, postRequest]);

    return {reqestResponse, reqestError, reqestLoading, activateNewClinicLicence};
}
