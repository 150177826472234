import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useCancelFirmwareUpdate = () => { 
  const [cancelFwError, setCancelFwError] = useState<any>('');
  const [cancelFwLoading, setCancelFwLoading] = useState<boolean>(false);
  const [cancelFwResponse, setCancelFwResponse] = useState<boolean>();
  const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);;
  const cancelDeviceFirmwareupdate = useCallback((deviceId:string) => {
    setCancelFwLoading(true);
    setCancelFwResponse(undefined);
    setCancelFwError('');
    
    const requestUrl = `${baseUrl}api/${v1}/device/${deviceId}/firmware-update/abort`;
    logger.logInformation(`Request to Cancel device FW update for the device ${deviceId} was made`);
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
        AuthConfig.getToken(scope).then(
            (token) => {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                axios
                    .delete(requestUrl, {headers})
                    .then(
                        () => {
                            setCancelFwResponse(true);
                            setCancelFwError('');
                            logger.logInformation(`Cancelled Device ${deviceId} FW update.`);
                        },
                        (error) => {     
                            setCancelFwError(error);
                            logger.logError(`Failed to cancel device ${deviceId} FW update.`);
                        }
                    )
                    .finally(() => {
                        setCancelFwLoading(false);
                    });
            },
            (error) => {
                setCancelFwLoading(false);
                setCancelFwError(error);
                setCancelFwResponse(false);
            }
        );
  },[baseUrl])

  return { cancelDeviceFirmwareupdate, cancelFwError, cancelFwLoading, cancelFwResponse };
  }

