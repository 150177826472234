import Dialog from "src/Components/Dialog/Dialog";
import tickIcon from 'src/images/tick-icon.svg';
import './ActivateLicenceSuccessInstruction.css';
import { Link } from "react-router-dom";
import Button from "src/Components/Button/Button";
import {useTranslation } from "react-i18next";

interface IActivateLicenseSuccessInstruction {
    onClose: () => void;
}
const ActivateLicenseSuccessInstruction: React.FC<IActivateLicenseSuccessInstruction> = ({ onClose }) => {
    const { t } = useTranslation();
    return (
        <div className="activate-license-container">
            <Dialog
                title=''
                headerAlign='left'
                submitText=""
                onClose={() => { onClose() }}
                onSubmit={() => { }}
                disableSubmit={true}
                hideSubmit={true}
            >
                <div className='mt-3' style={{ textAlign: 'center' }}>
                    <img src={tickIcon} alt='success' />
                    <div className="mt-4 success-msg">
                        {t('LicenseActivationSuccessMessage')}
                    </div>
                    <div className='mt-3 d-flex justify-content-center'>
                        {/* <ButtonWithIcon
                            backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                            onClick={() => { }}
                            label={t('AddCloudStorage')}
                            icon='bi bi-plus-lg'
                            testId='newUser'
                            color='white'
                            disable= {true}
                        /> */}
                    </div>
                    <div className='my-3'>
                        <Link to="/app">
                            <Button
                                text={t('DownloadAuraApp')}
                                backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                                borderColor="none"
                                color='white'
                                onClick={() => { }}
                            />
                        </Link>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
export default ActivateLicenseSuccessInstruction