import React, { useRef, useState } from "react";
import Modal from 'src/Utils/CssModules/modal.module.css';
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import LogOut from "../Authentication/Logout";
import './Profile.css'
import useOutsideClick from "src/Utils/Hooks/UseOutSideClick/UseOutSideClick";
import { useTranslation } from "react-i18next";
import About from "../About/About";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";

export interface IProfileParams {
  onClose: () => void;
}

const Profile: React.FC<IProfileParams> = ({ onClose }) => {
  const {currentUser} = useClinicContext()
  const userEmail =  currentUser? currentUser.clinicUser.email : '';
  const userLastName = useRef<string>(currentUser? currentUser.clinicUser.lastName : '');
  const userFirstName = useRef<string>(currentUser? currentUser.clinicUser.firstName : '');
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => onClose())
  const { t } = useTranslation();
  const [displayAboutPage, setDisplayAboutPage] = useState<boolean>(false);
  const [displayProfile, setDisplayProfile] = useState<boolean>(true);
  return (
    <>
      {displayAboutPage &&
        <About onClose={() => { setDisplayAboutPage(false); onClose() }} />}
      {
        displayProfile &&
        <div className="row">
          <div
            id="myModal"
            className={`${Modal.modal}`}
            style={{
              display: "flex",
              justifyContent: "end",
              zIndex: 1002,
              paddingTop: 0,
            }}
          >

            <div className={`${Modal.content} modal-content`} ref={ref}>
              <div className={`${Modal.body}`} style={{ display: "contents" }}>
                <div className="row">
                  <ProfileIcon firstName={userFirstName.current} lastName={userLastName.current} />
                </div>
                <div className="row profile-name">
                  <span>
                    {userFirstName.current} {userLastName.current}
                  </span>
                </div>
                <div className="row profile-email">
                  <span>{userEmail}</span>
                </div>
                <div className="row profile-about mt-5" style={{ textAlign: 'center', color: '#A8927D', fontSize: '15px', fontWeight: '500', cursor: 'pointer' }}>
                  <span onClick={() => {
                    setDisplayProfile(false);
                    setDisplayAboutPage(true)
                  }}>
                    {t('About')}
                  </span>
                </div>
                <div className="row profile-logout">
                  <LogOut />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Profile;