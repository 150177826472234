import { useCallback, useEffect, useState } from "react";
import Dialog from "src/Components/Dialog/Dialog";
import ErrorMessage from "src/Components/ErrorMessage/ErrorMessage";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { IInviteUserRequest, useClinicService } from "src/Utils/Hooks/GetClinicInfo/useClinicServic";
import './InviteUser.css'
import Button from "src/Components/Button/Button";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useTranslation } from "react-i18next";
interface IInviteUserParams {
  clinicId: string;
  onClose: () => void;
  setInfo: (message: string, type: InfoMessageType) => void;
  updatePageLoader: (load: boolean) => void;
}
const InviteUser: React.FC<IInviteUserParams> = ({ onClose, updatePageLoader, clinicId, setInfo }) => {
  const { addClinicUser, reqestError, reqestLoading, reqestResponse } = useClinicService()
  let req: IInviteUserRequest = {
    email: '', isAdmin: false
  }
  const [inviteUser, setInviteUser] = useState<IInviteUserRequest>(req);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [error, setError] = useState<string>('');
  const [display, setdisplay] = useState('block');
  const { t } = useTranslation();

  const isValidEmail = useCallback(() => {
    const exp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return exp.test(inviteUser.email);
  }, [inviteUser.email]);

  const validateEmail = () => {
    let msg = isValidEmail() ? '' : t('EmailAddressNotValid');
    setError(msg);
  };

  useEffect(() => {
    setDisableSubmit(!isValidEmail())
  }, [inviteUser.email, isValidEmail])

  useEffect(() => {
    if (reqestResponse) {
      logger.logInformation(`User is invited successfully. EmailId is ${inviteUser.email}, clinicId is ${clinicId}.`)
      setInfo(t('UserInvitedSuccessMsg'), InfoMessageType.success);
      onClose();
    }
    else {
      if (reqestError) {
        logger.logError(`Error occur while inviting user to clinic. EmailId is ${inviteUser.email}, clinicId is ${clinicId}.`)
        setInfo(reqestError, InfoMessageType.error);
        onClose();
      }
    }

  }, [reqestResponse, inviteUser.email, clinicId, setInfo, onClose, reqestError, t])
  useEffect(()=> {
    updatePageLoader(reqestLoading);
},[reqestLoading, updatePageLoader])
  const addUser = () => {
    addClinicUser(inviteUser, clinicId);
    setdisplay('none');
  }
  return (
    <div style={{display: display}}>
      <Dialog
        title='InviteNewUser'
        headerAlign='left'
        submitText="Send Invitation"
        onClose={() => onClose()}
        onSubmit={() => addUser()}
        disableSubmit={disableSubmit}
        hideSubmit={true}
      >
        <div className="label">
          {t('EmailAddress')}
        </div>
        <div className="row mt-2">
          <div className="col-8">
            <input
              type="text"
              id="email"
              className="form-control input-box"
              data-testid="e-mail"
              onChange={(e) => {
                setInviteUser({ ...inviteUser, email: e.target.value })
              }}
              onBlurCapture={validateEmail}
              style={
                error
                  ? { border: '1px solid red' }
                  : {}
              }
            />
          </div>
          <div className="col-4">
            <Button
              backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
              color='white'
              testId='ok'
              onClick={() => { addUser() }}
              text={t("SendInvitation")}
              height="48px"
              width="182px"
              disable={disableSubmit}
              borderRadius='24px'
            />
          </div>
          {error &&
            <ErrorMessage message={error}></ErrorMessage>
          }
        </div>
      </Dialog>
    </div>
  )
}
export default InviteUser;