import { useTranslation } from 'react-i18next';
import logo from 'src/images/logo.svg';
import './NoAccess.css';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import { Link } from 'react-router-dom';
import { Config } from 'src/Utils/ConfigService/LoadConfig';

const NoAccess : React.FC = () => {
    const { t } = useTranslation();
    const partnerPortalUrl =  Config.GetConfigData().partnerPortalUrl;
    return (
    <>
    <div style={{color:'#f5f2f0'}}>
      <div className="container">
        <div className="row header-container">
          <div className="pt-2 col-md-3 col-lg-3 col-xl-3">
            <div style={{ display: 'inline-block' }}>
              <span className="px-3"><img src={logo} alt='logo' /></span>
              <span className="portal-name">{t('ClinicPortal')}</span>
            </div>
          </div>
        </div>
    </div>
    </div>
    <div style={{maxWidth:'50%', marginLeft:'22%', marginTop:'14%'}}>
            <div className="row " >
                  <h2 className='header'>{t('Welcome')}</h2>
                  <div className='mt-3'>
                    <p className='text-center' style={{color:'black', fontWeight : 'bold'}}>
                       {t('WeNoticedWrongPortal')}.
                    </p>
                    <p className='text-center' style={{color:'black', marginLeft:'-1.5%'}}>
                        {t('PleaseUseLinkToPartnerPortal')}
                    </p>
                  </div>
            </div>
            <div style={{ marginLeft:'25%'}} className='mt-3'>
            <Link to={partnerPortalUrl} className='nounderline'>
                <ButtonWithIcon
                        label={t('GoToPartnerPortal')}
                        icon="bi bi-chevron-right"
                        onClick={() => {}}
                        testId="login"
                        color='#FFFFFF'
                        backgroundColor={'linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'} 
                        />
                    </Link>
            </div>
        </div>

    </>
    )
}

export default NoAccess;