import FlushAccordian from "src/Components/FlushAccordian/FlushAccordian";
import { ITechincalDocument } from "src/Utils/Hooks/Downloads/useGetTechicalDocuments";
import Document from "./Document";
export interface IDocuments {
    data : ITechincalDocument[]
    title:string
    id:string
}

const DocumentsDetails:React.FC<IDocuments> = ({data, title, id}) => {
   return(
    <>
                 <FlushAccordian title={title} id={id}>
                    <>
                        <div className="row" style={{ margin: 'auto' }}>
                            {data.map((doc, i) => (
                                <div className="col-md-4 col-sm-12" style={{ padding: '0' }} key={i}>
                                    <div className="m-1">
                                        <Document key={i} image={doc.previewData} title={doc.displayName} fileLink={doc.sasToken}/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                </FlushAccordian>
    </>
   )
}
export default DocumentsDetails;