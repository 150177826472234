import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneInputBox.css';
import { useState } from 'react';

// https://www.geeksforgeeks.org/how-to-add-phone-number-input-in-react-js/

interface IPhoneInputBox {
  width?: string;
  defaultCountryCode?: string;
  onChange: (text: string) => void;
  value?: string;
}

const PhoneInputBox: React.FC<IPhoneInputBox> = ({ width, value, defaultCountryCode, onChange }) => {
  let initialVal = value ? value : '';
  const [val, setVal] = useState(initialVal);

  return (
    <div id="phone-number">
        <PhoneInput
          country={defaultCountryCode? defaultCountryCode: ''}
          value={val}
          placeholder=''
          onChange={(_, _options: any, _event, formattedValue) => {
           setVal(formattedValue);
           onChange(formattedValue)
          }} 
          
          inputStyle={{ width: width }}
        />
    </div>
  )
}
export default PhoneInputBox;