import './Downloads.css';
import InfoBar, { IInfobarContent, InfoMessageType } from "../InfoBar/InfoBar";
import { useCallback, useState } from "react";
import TechnicalDocuments from "./TechnicalDocuments/TechnicalDocuments";
import TutorialVideos from "./TutorialVideos/TutorialVideos";
import { useInfobarTimeout } from 'src/Utils/Hooks/InfobarTimeout/useInfobarTimeout';
import MarketingMaterialDocuments from './MarketingMaterialDocuments/MarketingMaterialDocuments';

const Downloads = () => {

    const [infoBarData, setInfoBarData] = useState<IInfobarContent>({
        Message: '',
        MessageType: InfoMessageType.none
    })

    const [infoBar, setInfoBar] = useState<boolean>(false);
    const { setInfobarTimeout } = useInfobarTimeout();

    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        setInfoBar(true);
        let content: IInfobarContent = {
            Message: message,
            MessageType: messageType
        }
        setInfoBarData(content)
        setInfobarTimeout(() => setInfoBar(false));
    }, [setInfobarTimeout])

    return (
        <div className="p-3">
            {infoBar &&
                <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                    <InfoBar
                        Message={infoBarData.Message}
                        MessageType={InfoMessageType.error}
                        closeClick={() => {
                            setInfoBar(false);
                        }}
                    />
                </div>
            }
            <>
                <TechnicalDocuments
                    setInfo={setInfo}
                />
                <hr className='line mt-4'></hr>
                <TutorialVideos
                    setInfo={setInfo}
                />
                <hr className='line mt-4'></hr>
                <MarketingMaterialDocuments/>
            </>
        </div>
    )
}
export default Downloads;