import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import Button from "src/Components/Button/Button"

const UserWithAdminRole = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className='pt-3'>
                <Trans i18nKey='AdminAndMemberRegistrationSuccessInstruction' />
            </div>
            <div className='pt-5'>
               <Link to="/licences">
                    <Button
                        text={t('ManageLicenses')}
                        backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                        borderColor="#A8927D"
                        color="white"
                        onClick={() => { }}
                    />
                </Link>
            </div>
            {/* Will be enabled once the role based integrated on welcome page */}
            {/* <div className='pt-3'>
                <Link to="/app">
                    <Button
                        text={t('DownloadAuraApp')}
                        backgroundColor='none'
                        borderColor="none"
                        color="#A8927D"
                        onClick={() => { }}
                    />
                </Link>
            </div> */}
        </>
    )
}
export default UserWithAdminRole