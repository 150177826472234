import GridView from "../GridView/GridView"
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import { useGetClinicInfo } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";
import Loader from "../Loader/Loader";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import LogoText from "../LogoText/LogoText";
import { useTranslation } from "react-i18next";
import ClinicInfo from "./ClinicInfo/ClinicInfo";
import EditClinicData from "./EditClinicData/EditClinicData";
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
import ClinicSupportInfo from "./CinicSupportInfo";


const ClinicProfile: React.FC = () => {
    const {clinicError, clinicLoading, getClinicInfo } = useGetClinicInfo()
    const [enableEditClinic, setEnableEditClinic] = useState<boolean>(false);
    const { setInfobarTimeout } = useInfobarTimeout();
    const [showInfoBar, setInfoBar] = useState(false);
    const { t } = useTranslation();
    const [pageLoader, setPageLoader] = useState(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const {clinicInfo } = useClinicContext();

    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        setInfoBar(true);
        msg.current = message;
        msgType.current = messageType;
        if(messageType === InfoMessageType.success){
            getClinicInfo();
        }
        setInfobarTimeout(() => setInfoBar(false));
    }, [getClinicInfo, setInfobarTimeout])

    useEffect(() => {
        if (clinicError) {
            setInfo(clinicError, InfoMessageType.error)
        }
    }, [clinicError, setInfobarTimeout, setInfo])

    useEffect(() => {
        setPageLoader(clinicLoading);
    }, [clinicLoading])

    const address = useMemo(() => { 
        const addressParts = [clinicInfo?.distributorDetail.streetAddress, clinicInfo?.distributorDetail.city, clinicInfo?.distributorDetail.zipCode, clinicInfo?.distributorDetail.country];
        return addressParts.filter(part => part).join(", ")
      }, [clinicInfo?.distributorDetail]);
      
    return (
        <>
            {showInfoBar &&
                <InfoBar
                    Message={msg.current}
                    MessageType={msgType.current}
                    closeClick={() => {
                        setInfoBar(false);
                    }}
                />
            }
            {pageLoader && <Loader />}
            {clinicInfo &&
                <GridView title={t("ClinicInfo")}>
                    <div >
                        {
                            enableEditClinic ?
                                <EditClinicData
                                    clinicDetails={clinicInfo}
                                    onClose={() => setEnableEditClinic(false)}
                                    updatePageLoader={setPageLoader}
                                    setInfo={setInfo}/> :
                                <ClinicInfo
                                    clinicDetails={clinicInfo}
                                    onEditClinicClick={() => setEnableEditClinic(true)} />
                        }
                    </div>
                </GridView>
            }
            {clinicInfo?.distributorDetail &&
                <GridView title={t("AuraSalesContact")}>
                    <div className="d-flex flex-column">
                    <div className="mb-2">
                        <b>{clinicInfo?.distributorDetail.name}</b>
                    </div>
                    {clinicInfo?.distributorDetail.email && <LogoText logoSrc={mailLogo} text={clinicInfo?.distributorDetail.email} />}
                    {clinicInfo?.distributorDetail.contactNumber && <LogoText logoSrc={phoneLogo} text={clinicInfo?.distributorDetail.contactNumber} /> }
                    {address && <LogoText logoSrc={mapPinLogo} text={address} /> }
                    <ClinicSupportInfo clinicDetails={clinicInfo} />
                </div>
            </GridView>
            }
        </>
    )
}
export default ClinicProfile