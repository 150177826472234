import { Trans } from 'react-i18next';
import infoIcon from 'src/images/info-icon.svg';

const LicensesInstruction = () => {
    return (
        <>
            <div className='row p-2' style={{
                borderRadius: '24px',
                backgroundColor: 'rgba(168, 146, 125, 0.16)',
                color: 'black',
                fontSize: '14px',
                maxWidth: '400px'
            }}>
                <div className='col-2 pt-1'>
                    <img src={infoIcon} alt='infoIcon'/>
                </div>
                <div className='col-10'>
                    <Trans i18nKey='LicencesAllInUse'/>
                </div>
            </div>
        </>
    )
}

export default LicensesInstruction