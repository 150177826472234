import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { AuthConfig } from "src/AuthConfig";
import { v1 } from "src/Utils/ApiVersions";
export interface IDeviceFwUpdateStatus {
   requestId:string,
   desiredFirmwareVersion:string,
   initiatedOn:string,
   result:string,
   state:string,
   errorDetails:string
}

export const useGetDeviceFwUpdateStatus = () => {

    const [deviceFwDetails, setDeviceFwDetails] = useState<IDeviceFwUpdateStatus>();
    const [deviceFwError, setDeviceFwError] = useState<string>('');
    const [deviceFwLoading, setDeviceFwLoading] = useState<boolean>(false);
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);
    const scope = useMemo(() => Config.GetConfigData().auraWebService?.scopes[0], []);
    const getDeviceFwUpdateStatus = useCallback((deviceId:string, token:string) => {
        var promise = new Promise<IDeviceFwUpdateStatus>(async (resolve, reject) => {
            const requestUrl = `${baseUrl}api/${v1}/device/${deviceId}/firmware-status`;;
            const headers = {
                Authorization: `Bearer ${token}`,
            };
            axios
                .get(requestUrl, { headers })
                .then(
                    (response) => {
                        resolve(response.data)
                    },
                    (error) => {
                        if (error.message && error.message !== '') {
                            reject(error.message);
                        } else {
                            reject(error.response.data);
                        }
                    })
        })
        return promise;
    },[baseUrl]);

    const getFwUpdatesStatus = useCallback((deviceId: string) => {
        setDeviceFwLoading(true)
        AuthConfig.getToken(scope).then(
            (token) => {
                getDeviceFwUpdateStatus(deviceId, token).then(
                    (success) => {
                        setDeviceFwDetails(success);
                    },
                    (error) =>{
                        setDeviceFwError(error)
                    }
                ).finally(() => setDeviceFwLoading(false))
            },
            (error) => { 
                setDeviceFwError(error)
                setDeviceFwLoading(false)
            }
        )
    },[getDeviceFwUpdateStatus,scope])
    return { getFwUpdatesStatus, deviceFwDetails, deviceFwError, deviceFwLoading };
    
}