import React, { useCallback, useEffect, useRef, useState } from "react"
import Header from "../Header/Header"
import LayoutWrapper from "../LayoutWrapper/LayoutWrapper"
import NavBar from "../Navbar/NavBar"
import NavbarContentWrapper from "../LayoutWrapper/NavbarContentWrapper"
import { Outlet, useNavigate } from "react-router-dom"
import { useGetCurrentUserInfo } from "src/Utils/Hooks/GetClinicInfo/useGetCurrentUserInfo"
import { useClinicContext } from "src/Utils/Hooks/ClinicContext"
import { ClinicAdmin, IClinicUserInfo } from "src/Utils/Hooks/GetClinicInfo/useGetUserDetails"
import Loader from "../Loader/Loader"
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar"
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout"
import { useMsal } from '@azure/msal-react';
import { logger } from "src/Utils/LoggerService/LoggerService"
const MainContent: React.FC = () => {

    var { currentUser, clinicLoading, loading, error, clinicError, statusCode } = useGetCurrentUserInfo();
    const [infoBar, setInfoBar] = useState<boolean>(false);
    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const { setInfobarTimeout } = useInfobarTimeout();
    const { setCurrentUser } = useClinicContext();
    const navigate = useNavigate()
    const { instance } = useMsal();
    const setInfo = useCallback((message: string, messageType: InfoMessageType) => {
        setInfoBar(true);
        msg.current = message;
        msgType.current = messageType;
        setInfobarTimeout(() => setInfoBar(false));
    }, [setInfobarTimeout]);

    useEffect(() => {
        if (error !== '') {
            setInfo(error, InfoMessageType.error)

        }
    }, [error, setInfo])
    useEffect(() => {
        if(statusCode === 404) {
            const userOid = instance.getActiveAccount()?.idTokenClaims?.oid;
            logger.logWarning(`User with object id ${userOid} is trying to access Clinic portal, but does not has access to it.`);
            navigate('/noaccess')
        }
        if (clinicError !== '') {
            setInfo(clinicError, InfoMessageType.error)
        }
    }, [clinicError, setInfo, navigate, statusCode, instance])
    useEffect(() => {
        if (currentUser) {
            var isAdmin = currentUser.roles.indexOf(ClinicAdmin) !== -1
            var user: IClinicUserInfo = {
                clinicUser: currentUser,
                isClinicAdmin: isAdmin
            }
            setCurrentUser(user)
        }
    }, [currentUser, setCurrentUser])


    return (
        <>
            <Header />
            <LayoutWrapper>
                <>
                    <NavBar />
                    <NavbarContentWrapper >
                        {infoBar && (
                            <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                                <InfoBar
                                    Message={msg.current}
                                    MessageType={msgType.current}
                                    closeClick={() => {
                                        setInfoBar(false);
                                    }}
                                />
                            </div>
                        )}
                        {
                            (loading || clinicLoading) &&
                            <Loader />
                        }
                        <Outlet />
                    </NavbarContentWrapper>
                </>

            </LayoutWrapper>
        </>
    )
}
export default React.memo(MainContent)