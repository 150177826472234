import { useCallback, useRef } from 'react';

export const useInfobarTimeout = () => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const defaultDelay = 10000;

    const setInfobarTimeout = useCallback((callback: () => void) => {
        clearTimeout(timeoutRef.current as NodeJS.Timeout);
        const timeoutId = setTimeout(() => {
            callback();
        }, defaultDelay);
        timeoutRef.current = timeoutId;
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    return { setInfobarTimeout };
};
