import './WelcomePage.css';
import tickIcon from 'src/images/tick-icon.svg';
import UserWithAdminRole from './UserWithAdminRole/UserWithAdminRole';
import { useTranslation } from 'react-i18next';

const WelcomePage = () => {
    const { t } = useTranslation();
    return (
        <div className='welcome-component'>
            <div className="card p-5">
                <div className="card-body px-5">
                    <img src={tickIcon} alt='success' />
                    <div className='registrationSuccessful pt-4'>
                        {t('RegistrationSuccessful')}
                    </div>
                    {
                         <UserWithAdminRole /> 
                    }
                </div>
            </div>
        </div>
    )
}
export default WelcomePage;