import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { IDeviceDetails } from './useGetDeviceInfo';
import { v1 } from 'src/Utils/ApiVersions';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { IDevice } from '../GetClinicInfo/useGetClinicInfo';
import { Config } from 'src/Utils/ConfigService/LoadConfig';
import { AuthConfig } from 'src/AuthConfig';

export const useDeviceDetails = () => {
    const [deviceFetchDetails, setDeviceFetchDetails] = useState<IDeviceDetails>();
    const [deviceFetchError, setDeviceFetchError] = useState<string>('');
    const scope = useMemo(() => Config.GetConfigData().auraWebService?.scopes[0], []);
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl, []);
    const fetchDeviceDetails = useCallback(
        async (device: IDevice, token: string) => {
            var promise = new Promise<IDeviceDetails>(async (resolve, reject) => {
                const requestUrl = `${baseUrl}api/${v1}/device/${device.deviceId}`;
                const headers = {
                    Authorization: `Bearer ${token}`,
                };
                axios
                    .get(requestUrl, { headers })
                    .then(
                        (response) => {
                            let details: IDeviceDetails = {
                                info: device,
                                firmwreVersionInfo: response.data,
                            };
                            const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                            logger.logInformation(`Get request to ${requestUrl} for device details was successful for device id: ${device.deviceId} with ${status}.`);
                            resolve(details);
                        },
                        (error) => {
                            const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                            logger.logError(`Error occurred to fetch device details for ${requestUrl}: ${status}.`);
                            if (error.message && error.message !== '') {
                                reject(error.message);
                            } else {
                                reject(error.response.data);
                            }
                        }
                    );
            });
            return promise;
        }, [baseUrl]);

    const fetchDeviceInfoById = useCallback((device: IDevice) => {
             AuthConfig.getToken(scope).then(
            (token) => {
                fetchDeviceDetails(device, token).then(
                    (success) => {
                        setDeviceFetchDetails(success);
                    },
                    (error) =>{
                        setDeviceFetchError(error)
                    }
                )
            },
            (error) => { 
                setDeviceFetchError(error)
            }
        )
        },
        [fetchDeviceDetails, scope]
    );

    return { fetchDeviceDetails, fetchDeviceInfoById, deviceFetchDetails, deviceFetchError };
};
