import Dialog from "../Dialog/Dialog";
import logo from 'src/images/logo.svg';
import './About.css'
import { useTranslation } from "react-i18next";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import ExternalLinkButton from "src/SharedComponents/ExternalLinkButton/ExternalLinkButton";

interface IAboutParams {
    onClose: () => void;
}

const About: React.FC<IAboutParams> = ({ onClose }) => {
    const { t } = useTranslation();
    var aboutPageLinks = Config.GetConfigData().aboutPageLinks;
    return (
        <div className="about-page">
            <Dialog
                title=''
                submitText=""
                onClose={() => { onClose() }}
                onSubmit={() => { }}
                hideSubmit={true}
            >
                <>
                    <div className="row mb-5">
                        <img src={logo} alt='logo' height='46px' />
                    </div>
                    <div className="row d-flex justify-content-center mt-2 copyright">
                        {t('CopyRight')}
                    </div>
                    <div className="row d-flex justify-content-center mt-4 mb-5">
                        <a href={aboutPageLinks.website} target="_blank" rel="noopener noreferrer" id="website" className="website">
                            {t('Website')}
                        </a>
                    </div>
                    <div className="row mt-5 pt-4" >
                        <ExternalLinkButton link={aboutPageLinks.privacyNotice} buttonText={t('PrivacyNotice')} id='privacynotice' width='85%' />
                    </div>
                    <div className="row mt-3">
                        <ExternalLinkButton link={aboutPageLinks.eula} buttonText={t('EndUserLicenceAgreement')} id='enduser' width='85%' />
                    </div>
                    <div className="row mt-3">
                        <ExternalLinkButton link={aboutPageLinks.thirdPartyLicences} buttonText={t('ThirdPartyLicences')} id='enduser' width='85%' />
                    </div>
                </>
            </Dialog>
        </div>
    )
}
export default About