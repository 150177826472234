import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import './AppLayout.css';
import React, { useEffect, useState } from 'react';
import { InteractionType } from '@azure/msal-browser';
import { ClinicContext } from 'src/Utils/Hooks/ClinicContext';
import { IClinicInfo } from 'src/Utils/Hooks/GetClinicInfo/useGetClinicInfo';
import MainContent from './MainContent';
import { IClinicUserInfo } from 'src/Utils/Hooks/GetClinicInfo/useGetUserDetails';

const MainLayout: React.FC = () => {
    const { login, error } = useMsalAuthentication(InteractionType.Redirect);
    const isAuthenticated = useIsAuthenticated();
    const [clinicInfo, setClinicInfo] = useState<IClinicInfo>();
    const [currentUser, setCurrentUser] = useState<IClinicUserInfo>();

    useEffect(() => {
        if (error) {
            login(InteractionType.Popup);
        }
    }, [error, login]);

    return (
        <>
            {isAuthenticated &&
                <>
                <ClinicContext.Provider value={{clinicInfo, setClinicInfo, currentUser, setCurrentUser}}>
                      <MainContent />
                </ClinicContext.Provider>
                </>
            }
        </>
    );
};
export default React.memo(MainLayout);
