import { useCallback, useMemo } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { v1 } from "src/Utils/ApiVersions";
import { useDeleteRequest } from "../ApiWrappers/useDeleteRequest";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useDeleteUser = () => {

    const {deleteRequest, requestError: deleteUserError, requestLoading: deleteUserLoading, requestResponse: deleteUserResponse} = useDeleteRequest()
    const baseUrl = useMemo(() => Config.GetConfigData().serviceUrl,[]);

    const deleteClinicUser = useCallback((userId: string, clinicId: string) => {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicId}/user/${userId}`;
        logger.logInformation(`A request has been submitted to delete user ${userId} from clinic ${clinicId}.`)
        deleteRequest(requestUrl);
    },[baseUrl, deleteRequest]) 

    return { deleteClinicUser, deleteUserResponse, deleteUserLoading, deleteUserError };
}