import { useState } from "react";
import './Accordian.css';
interface IAccordion {
  header: string;
  children: React.ReactNode;
}
const Accordion: React.FC<IAccordion> = ({ header, children }) => {
  const [rotateIcon, setRotate] = useState(false);
  const handleRotate = () => { setRotate(!rotateIcon) };

  const cl = rotateIcon ? "bi bi-chevron-up" : "bi bi-chevron-down";
  return (
    <>
      <div className="mt-2" onClick={handleRotate} style={{ cursor: 'pointer', marginLeft: '8%' }} data-testid="iconWithHeader">
        <h3 data-testid="header" className="accordian" >
          <i
            style={{ color: '#A8927D', marginRight: '10px', paddingTop: '6px', marginTop: '10px' }}
            className={cl}
            data-testid="icon">
          </i>
          {header}
        </h3>
      </div>
      {rotateIcon &&
        <div data-testid="content">
          {children}
        </div>
      }

    </>
  )
}
export default Accordion;