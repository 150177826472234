import line from 'src/images/line.png';
const LineSeparation = () => {
    return(
        <>
          <i style={{ color: '#A8927D' }}>
            <img src={line} alt="Logo" style={{height:'1px', width:'99%'}}/>
          </i>
        </>
    )
}
export default LineSeparation;