import tableStyles from 'src/Utils/CssModules/table.module.css';
import arrowSquareIcon from "src/images/arrowSquare-icon.svg";
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter";
import { useTranslation } from 'react-i18next';
import React from 'react';
import './WindowsAppReleaseHistory.css';
import { IWindowsAppVersionInfo } from 'src/Utils/Hooks/ManageAppReleases/useGetAppReleasesInfo';
import { logger } from 'src/Utils/LoggerService/LoggerService';

interface IWindowsAppReleaseHistoryParams {
    windowsAppReleases: IWindowsAppVersionInfo[];
}
const WindowsAppReleaseHistory: React.FC<IWindowsAppReleaseHistoryParams> = ({ windowsAppReleases }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={`releaseHistory ${tableStyles.fixTableHead} ${tableStyles.roundedTable} mt-4`}>
            {windowsAppReleases && windowsAppReleases.length > 0 &&
                <table data-testid="table" className={`table`}>
                    <thead className={`${tableStyles.tableHead}`}>
                        <tr key={'header'} className="header">
                            <th className={`${tableStyles.heading} align-center`}><b>{t("Date")}</b></th>
                            <th className={`${tableStyles.heading} align-center`}><b>{t("ReleaseNotes")}</b></th>
                        </tr>
                    </thead>
                    <tbody>
                       {windowsAppReleases.map((item, index) => (
                            <tr key={item.version}>
                                <td className={`${tableStyles.data} align-center`}>{formatDateString(item.date)}</td>
                                <td className={`${tableStyles.data} align-center`}
                                onClick={() => {logger.logInformation(`User downloaded release notes for "windows" with version ${item.version}.`)} }
                                 >
                                    <a
                                        href={item.releaseNotesUrl}
                                        style={{ cursor: 'pointer', display: 'block' }}
                                        target="_blank"
                                        rel="noOpener noReferrer"
                                    >
                                        <span className='version' id='version'>
                                            {t("Version")} {item.version}
                                        </span>
                                        <img src={arrowSquareIcon} alt='arrow-square-icon' />
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                 }
            </div>
        </>
    )
}
export default WindowsAppReleaseHistory