import axios from "axios";
import { useCallback, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const useDeleteRequest = () => {
  const [requestError, setRequestError] = useState<string>('');
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [requestResponse, setRequestResponse] = useState<boolean>();

  const deleteRequest = useCallback((requestUrl: string) => {
    setRequestLoading(true);
    setRequestResponse(false);
    setRequestError('');

    const scope = Config.GetConfigData().auraWebService?.scopes[0];
    AuthConfig.getToken(scope).then(
      (token) => {
        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
        logger.logInformation(`Access token received successfully for scope: ${scope} and request url: ${requestUrl}`);
        
        axios.delete(requestUrl, { headers })
          .then(
            (response) => {
              setRequestResponse(true);
              setRequestError('');
              const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
              logger.logInformation(`Response details for ${requestUrl}: ${status}.`);
            },
            (error) => {
              const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
              logger.logError(`Error details for ${requestUrl}: ${status}.`);
              if (error.response && error.response.data !== '') {
                if (error.response.data.detail !== '' && error.response.data.detail !== undefined) {
                  setRequestError(error.response.data.detail);
                } else {
                  setRequestError(error.response.data);
                }
              }
              else if (error.message !== '') {
                setRequestError(error.message);
              }
              else {
                setRequestError(error);
              }
            }
          )
          .finally(() => {
            setRequestLoading(false);
          });
      },
      (error) => {
        setRequestLoading(false);
        setRequestError(error);
        setRequestResponse(false);
      }
    );
  }, []);

  return { deleteRequest, requestError, requestLoading, requestResponse };
};
