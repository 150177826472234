import React from 'react';

interface LogoTextProps {
  logoSrc: string;
  text?: string;
  color?: string;
  additionalText?:string;
}

const LogoText: React.FC<LogoTextProps> = ({ logoSrc, text, color, additionalText }) => {
  const iconColor = color || '#A8927D';
  return (
    <div className="mb-2">
      <i style={{ color: iconColor }}>
        <img src={logoSrc} alt="Logo" />
      </i>
      <span style={{ paddingLeft: '10px', color: 'black' }}>{text}</span>
      {additionalText && <span style={{ paddingLeft: '1px', color: iconColor }}>{additionalText}</span>}
    </div>
  );
};

export default LogoText;
