import Button from "src/Components/Button/Button";
import InputBoxWithLabel from "src/SharedComponents/InputBoxWithLabel/InputBoxWithLabel";
import PhoneInputBox from "src/SharedComponents/PhoneInputBox/PhoneInputBox";
import TextBox from "src/SharedComponents/TextBox/TextBox";
import { IClinicInfo } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";
import './EditClinicData.css';
import { useCallback, useEffect, useState } from "react";
import { isValidEmail, isValidPhoneNumber } from "src/Utils/Hooks/ValidationUtils/ValidationUtils";
import { useTranslation } from "react-i18next";
import { useEditClinic } from "src/Utils/Hooks/EditClinic/useEditClinic";
import ErrorMessage from "src/Components/ErrorMessage/ErrorMessage";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";

interface IEditClinicDataParams {
    clinicDetails: IClinicInfo;
    updatePageLoader: (load: boolean) => void;
    onClose: () => void;
    setInfo: (message: string, type: InfoMessageType) => void;
}
const EditClinicData: React.FC<IEditClinicDataParams> = ({ onClose, updatePageLoader, clinicDetails, setInfo }) => {
    const { t } = useTranslation();
    const { editClinic, reqestResponse, reqestLoading, reqestError } = useEditClinic();
    const [enableSave, setEnableSave] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState({
        name: '',
        email: '',
        contactNumber: '',
        address: '',
        clinicAdminEmail: ''
    });
    const [editedClinicDetails, setEditedClinicDetails] = useState({
        name: clinicDetails.name,
        contactNumber: clinicDetails.contactNumber,
        email: clinicDetails.email,
        address: clinicDetails.address
    });
    useEffect(() => {
        if (reqestResponse) {
            logger.logInformation(`Edited clinic successfully, clinicId is ${clinicDetails.clinicId}.`)
            setInfo(`Edited clinic ${clinicDetails.name} successfully`, InfoMessageType.success);
            onClose();
        }
    }, [reqestResponse, setInfo, onClose, clinicDetails])
    useEffect(() => {
        if (reqestError !== '') {
            logger.logInformation(`Error occured while editing the clinic info and clinic id: ${clinicDetails.clinicId}.`)
            setInfo(reqestError, InfoMessageType.error);
            onClose();
        }
    }, [reqestError, setInfo, onClose, clinicDetails.clinicId])
    useEffect(() => {
        updatePageLoader(reqestLoading)
    }, [reqestLoading, updatePageLoader])
    useEffect(()=> {
        let infoChanged = (editedClinicDetails.name !== clinicDetails.name) || (editedClinicDetails.address !== clinicDetails.address) || 
        (editedClinicDetails.email !== clinicDetails.email) || (editedClinicDetails.contactNumber !== clinicDetails.contactNumber);
        setEnableSave(infoChanged);
    }, [editedClinicDetails, clinicDetails])
    const isValidRequest = useCallback(() => {
        const errors: any = {};
        let isValid = true;
        setValidationErrors(errors);
        if (editedClinicDetails.name === '') {
            errors.name = t('NameRequired');
            isValid = false;
        }

        if (editedClinicDetails.email === '') {
            errors.email = t('EmailAddressRequired');
            isValid = false;
        }

        else if (!isValidEmail(editedClinicDetails.email)) {
            errors.email = t('InvalidEmailAddress');
            isValid = false;
        }

        if (editedClinicDetails.contactNumber === '') {
            errors.contactNumber = t('PhoneRequired');
            isValid = false;
        }
        else if (!isValidPhoneNumber(editedClinicDetails.contactNumber)) {
            errors.contactNumber = t('InvalidPhoneNumber');
            isValid = false;
        }

        if (editedClinicDetails.address === '') {
            errors.address = t('AddressRequired');
            isValid = false;
        }
        setValidationErrors(errors);

        return isValid;
    }, [editedClinicDetails, setValidationErrors, t])
    const editClinicInfo = useCallback(() => {
        if (isValidRequest()) {
            editClinic(editedClinicDetails, clinicDetails.clinicId);
        }
    }, [clinicDetails.clinicId, editedClinicDetails, editClinic, isValidRequest])
    return (
        <>
            <div className="row mt-2">
                <InputBoxWithLabel
                    value={editedClinicDetails.name}
                    onChange={(e) => { setEditedClinicDetails({ ...editedClinicDetails, name: e }) }}
                    label={t('ClinicName')} />
                <ErrorMessage message={validationErrors.name}></ErrorMessage>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <InputBoxWithLabel
                        onChange={(e) => { setEditedClinicDetails({ ...editedClinicDetails, email: e }) }}
                        label={t('ClinicEmailAddress')}
                        value={editedClinicDetails.email} />
                    <ErrorMessage message={validationErrors.email}></ErrorMessage>
                </div>
                <div className="col-6">
                    <div className="label mb-2">
                        {t('ClinicPhoneNumber')}
                    </div>
                    <PhoneInputBox
                        onChange={(e) => { setEditedClinicDetails({ ...editedClinicDetails, contactNumber: e }) }}
                        value={editedClinicDetails.contactNumber} />
                    <ErrorMessage message={validationErrors.contactNumber}></ErrorMessage>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-6">
                    <TextBox label={t('Address')}
                        onChange={(e) => { setEditedClinicDetails({ ...editedClinicDetails, address: e }) }}
                        value={editedClinicDetails.address} />
                    <ErrorMessage message={validationErrors.address}></ErrorMessage>
                </div>
            </div>
            <div className="row d-flex justify-content-center me-5">
                <div className="button-container mt-5 mb-4">
                    <div className="button-wrapper">
                        <Button
                            backgroundColor='none'
                            color='#A8927D'
                            testId='cancel'
                            onClick={() => { onClose() }}
                            text={t('Cancel')}
                            width='141px'
                            height='48px'
                            borderRadius='24px'
                            borderColor="#D0D0D0"
                        />
                    </div>
                    <div className="button-wrapper me-4 pe-3">
                        <Button
                            testId='save'
                            onClick={() => { editClinicInfo() }}
                            text={t('Save')}
                            width='141px'
                            disable={!enableSave}
                        />
                    </div>
                </div>
            </div>

        </>
    );
}
export default EditClinicData