import axios from "axios";
import { useCallback, useState } from "react";
import { AuthConfig } from "src/AuthConfig";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { logger } from "src/Utils/LoggerService/LoggerService";

export const  usePutRequest = () => { 

  const [reqestError, setReqestError] = useState<any>('');
  const [reqestLoading, setReqestLoading] = useState<boolean>(false);
  const [reqestResponse, setReqestResponse] = useState<any>();

  const putRequest= useCallback(<T extends Partial<T>>(request: T, requestUrl:string) => {
    setReqestLoading(true);
    setReqestResponse(undefined);
    setReqestError('');
    const scope = Config.GetConfigData().auraWebService?.scopes[0];
        AuthConfig.getToken(scope).then(
            (token) => {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
                logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
                axios
                    .put(requestUrl, request, {headers})
                    .then(
                        (response) => {
                            setReqestResponse(response);
                            const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                            logger.logInformation(`Response details for ${requestUrl}: ${status}`);
                            setReqestError('');
                        },
                        (error) => {
                            const status = error.response && error.response.status ? `Status Code: ${error.response.status}` : 'Status Code: N/A';
                            logger.logError(`Error details for request: ${JSON.stringify(request)}, Url: ${requestUrl}: ${status}.`);
                            if (error.response && error.response.data !== '') {
                                if(error.response.data.detail !== '' && error.response.data.detail !== undefined) {
                                    setReqestError(error.response.data.detail);
                                } else {
                                    setReqestError(error.response.data);
                                }
                            } 
                            else if(error.message !== '')  {
                                setReqestError(error.message);
                            }
                            else{
                                setReqestError(error);
                            }
                        }
                    )
                    .finally(() => {
                        setReqestLoading(false);
                    });
            },
            (error) => {
                setReqestLoading(false);
                setReqestError(error);
                setReqestResponse(undefined);
            }
        );
  },[])

  return { putRequest, reqestError, reqestLoading, reqestResponse };
  }