import React from 'react';
import { useRef } from 'react';

import { TopItems } from './NavbarItems';
import SubNavBar from './SubNavBar/SubNavBar';
export interface INavbarItem{
  id:string;
  name:string
  testid:string;
  routePath:string;
  hasChildren:boolean;
}
export interface INavbarItems {
  items: INavbarItem[]
}

const NavBar: React.FC = () => {
  const refNavbarItems = useRef<INavbarItems>({items: TopItems});
  return (
      <SubNavBar itemsList={refNavbarItems.current.items}  />
  );
};
export default React.memo(NavBar);
