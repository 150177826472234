import './ButtonWithIcon.css'

export interface IButtonParams {
    label: string;
    icon?: string;
    color: string;
    backgroundColor: string;
    onClick: () => void;
    testId?: string;
    disable?:boolean;
    fontSize?: string;
    border?: string
    
};
const ButtonWithIcon: React.FC<IButtonParams> = ({ label, icon, backgroundColor, border, color, testId, fontSize, onClick, disable = false }) => {
    return (
        <button 
              type='button'
              className='btn buttonWrapper' 
              data-testid={label} 
              id={testId}
              style={{ background: `${disable ? '#A8927D29' : backgroundColor}`, color: `${disable ? '#A8927D' : color}`, borderColor: color, fontSize: fontSize, border: disable? '1px rgba(168, 146, 125, 0.16)': border }} 
              onClick={() => onClick()}
              disabled ={disable}
              >
            {icon &&
                <span className='iconWrapper'>
                    <i className={`${icon}`}></i>
                </span>
            }
            {label}
        </button>
    );
};

export default ButtonWithIcon;