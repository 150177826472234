import LogoText from "src/Components/LogoText/LogoText"
import { IClinicInfo } from "src/Utils/Hooks/GetClinicInfo/useGetClinicInfo";
import mailLogo from 'src/images/mail.svg';
import phoneLogo from 'src/images/phone.svg';
import mapPinLogo from 'src/images/map-pin.svg';
import MoreOptionsMenu from "src/Components/MoreOptions/MoreOptions";
import { useTranslation } from "react-i18next";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";

interface IClinicInfoParams {
    clinicDetails: IClinicInfo;
    onEditClinicClick: () => void;
}
const ClinicInfo: React.FC<IClinicInfoParams> = ({ clinicDetails, onEditClinicClick }) => {
    const { t } = useTranslation();
    const {currentUser} = useClinicContext()
    return (
        <>
            <div className="row">
                <div className="col-9">
                    <b>{clinicDetails?.name}</b>
                </div>
                <div className="col-3 d-flex justify-content-end"  style={{ height: '32px' }}>
                    <MoreOptionsMenu id='three-dots-clinic-info'  disabled={!currentUser?.isClinicAdmin}>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><div className="dropdown-item" key="1" id='edit-clinic-data' onClick={onEditClinicClick}>{t('EditClinicData')}</div></li>
                        </ul>
                    </MoreOptionsMenu>
                </div>
            </div>
            {clinicDetails.email && <LogoText logoSrc={mailLogo} text={clinicDetails.email} /> }
            {clinicDetails.contactNumber && <LogoText logoSrc={phoneLogo} text={clinicDetails.contactNumber} /> }
            {clinicDetails.address && <LogoText logoSrc={mapPinLogo} text={clinicDetails.address} /> }
        </>
    )
}
export default ClinicInfo