import React, { useCallback, useRef, useState } from 'react';
import Modal from 'src/Utils/CssModules/modal.module.css';
import Button from '../Button/Button';
import './SmallDialog.css'
import useOutsideClick from 'src/Utils/Hooks/UseOutSideClick/UseOutSideClick';
import { useTranslation } from 'react-i18next';
export interface ISmallDialogParams {
    title: string
    children: React.ReactNode,
    onSubmit: () => void,
    onClose: () => void,
    submitButtonText: string;
    submitButtonColor?: string;
    cancelButtonText?: string;
    cancelButtonColor?: string;
}
const SmallDialog: React.FC<ISmallDialogParams> = ({ title, children, onSubmit, onClose, submitButtonText, submitButtonColor, cancelButtonText, cancelButtonColor }) => {
    const [display, setdisplay] = useState('block');
    const wrapperRef = useRef<HTMLDivElement>(null);
    let cancelBtTxt = cancelButtonText?? 'Cancel';
    const { t } = useTranslation();
    useOutsideClick(wrapperRef, () => { close()})
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    const submit = useCallback(() => {
        setdisplay('none');
        onSubmit();
    }, [onSubmit]);
    return (
        <div className='row'>
            <div id="myModal" className={`${Modal.modal}`} style={{ display: display, zIndex: 1003 }}>
                <div className={`${Modal.content} smallcontent`} ref={wrapperRef}>
                    <div className={Modal.header} style={{ textAlign: 'center' }}>
                        <h3 style={{fontWeight:'600'}}>{t(title)}</h3>
                    </div>
                
                    <div className={Modal.body} style={{padding:'1px 30px 0px 40px'}}>
                        {children}
                    </div>
                
                    <div className={Modal.footer} style={{padding:'20px 17px 20px 31px'}}>
                        <div className="row" >
                         <div className='col-6 d-flex justify-content-end'>
                            <Button
                                  backgroundColor='none'
                                  color={cancelButtonColor? cancelButtonColor: '#A8927D'}
                                  testId='close'
                                  onClick={() => { close() }}
                                  text={cancelBtTxt}
                                  width='141px'
                                  height='48px'
                                  borderRadius='24px'
                                  borderColor={cancelButtonColor? cancelButtonColor: "#D0D0D0"}
                                />
                            </div>
                            <div className='col-auto me-auto'>
                                <Button
                                  backgroundColor={submitButtonColor? submitButtonColor: 'linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'}
                                  color='white'
                                  testId='ok'
                                  onClick={() => { submit() }}
                                  text={submitButtonText}
                                  width='141px'
                                  height='48px'
                                  borderRadius='24px'
                                />
                            </div>
  

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SmallDialog;