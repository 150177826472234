import { useCallback } from "react";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useDeleteRequest } from "../ApiWrappers/useDeleteRequest";
import { logger } from "src/Utils/LoggerService/LoggerService";

export interface IReleaseLicenceRequest{
    sessionId: string;
    clinicId: string;
}
export const useReleaseLicence = () => {
    const {deleteRequest, requestResponse, requestError, requestLoading} = useDeleteRequest();
   const baseUrl = Config.GetConfigData().auraLicensingService.serviceUrl;
    const releaseLicence = useCallback((req: IReleaseLicenceRequest) => {
        const requestUrl = `${baseUrl}api/v1/license/clinic/${req.clinicId}/session/${req.sessionId}`;
        logger.logInformation(` request to release licence from clinic id:${req.clinicId} request: ${JSON.stringify(req)}`);
        deleteRequest(requestUrl);
    }, [baseUrl, deleteRequest]);

    return {requestResponse, requestError, requestLoading, releaseLicence};
}
