// This function takes a date string as input and formats it as 'dd.mm.yyyy'.
export function formatDateString(inputDate: string): string {
    const date = new Date(inputDate);

    // Check if the date is valid by comparing it to NaN (Not a number)
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }
  
    // Extract the year, month, and day components from the Date object and format them.

    // Ensure year has 4 digits by padding with '0' if needed.
    const year = date.getFullYear().toString().padStart(4, '0');

    // Ensure month and day has 2 digits by padding with '0' if needed.
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${day}.${month}.${year}`;
}
  