import React, { useCallback, useState } from 'react';
import Modal from 'src/Utils/CssModules/modal.module.css';
import Button from '../Button/Button';
import closeIcon from 'src/images/close-icon.svg';
import './Dialog.css';
import { useTranslation } from 'react-i18next';

export interface IConfirmDialogParams {
    title: string
    children: React.ReactNode,
    onSubmit: () => void,
    onClose: () => void,
    submitText: string,
    disableSubmit?: boolean,
    headerAlign?: any,
    hideSubmit?: boolean,
    id?: string,
}
const ConfirmDialog: React.FC<IConfirmDialogParams> = ({ title, children, submitText, onSubmit, onClose, headerAlign, id, disableSubmit, hideSubmit }) => {
    const [display, setdisplay] = useState('block');
    const { t } = useTranslation();
    
    const close = useCallback(() => {
        setdisplay('none');
        onClose();
    }, [onClose]);
    const submit = useCallback(() => {
        setdisplay('none');
        onSubmit();
    }, [onSubmit]);
    return (
        <div className='row' id={id}>
            <div id="myModal" className={`${Modal.modal}`} style={{ display: display, zIndex: 1003 }}>
                <div className={Modal.content} id="modalContent">
                    <div className={`${Modal.header} row`} style={{ textAlign: headerAlign, display: 'flex' }}>
                        <p className='col-7'>{t(title)}</p>
                        <span className='col-5 d-flex justify-content-end' onClick={() => { close() }}>
                            <img src={closeIcon} className='close-icon' alt="close"/>
                        </span>
                    </div>

                    <div className={Modal.body}>
                        {children}
                    </div>
                    <div className={Modal.footer} >
                        <div className="row" >
                            <div className='col-auto me-auto' hidden={hideSubmit}>
                                <Button
                                    backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                                    color='white'
                                    testId='ok'
                                    onClick={() => { submit() }}
                                    text={submitText}
                                    width='100px'
                                    disable={disableSubmit}
                                    borderRadius='24px'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ConfirmDialog;